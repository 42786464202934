import {ChangeDetectionStrategy, Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {Timesheet, WeekDay} from '@app/spurado';
import { DatePipe, NgIf } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';

@Component({
    selector: 'spurado-week-navigator',
    templateUrl: './week-navigator.component.html',
    styleUrls: ['./week-navigator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        DatePipe
    ],
    standalone: true,
    imports: [NgIf, MatIconButton, MatIcon, MatButton, TranslateModule, DatePipe]
})
export class WeekNavigatorComponent {

    @Input()
    displayWeekend: boolean;
    @Input()
    isLoading: boolean;
    @Input()
    disabled: boolean = false;
    @Input()
    displayedTimesheet: Timesheet;
    @Input()
    week: { [index: string]: WeekDay };
    @Input()
    currentWeek: number;

    @Output()
    initialLoading = new EventEmitter();
    @Output()
    clickNext = new EventEmitter();
    @Output()
    clickPrevious = new EventEmitter();

    constructor(private datePipe: DatePipe) {
    }

    @HostListener('document:keyup.ArrowLeft', ['$event.target'])
    public previous(element: HTMLElement): void {
        if (element.localName === 'body' && element.classList.contains('mat-body') && element.classList.contains('mat-app-background')) {
            this.loadPreviousWeek(null);
        }
    }

    @HostListener('document:keyup.ArrowRight', ['$event.target'])
    public next(element: HTMLElement): void {
        if (element.localName === 'body' && element.classList.contains('mat-body') && element.classList.contains('mat-app-background')) {
            this.loadNextWeek(null);
        }
    }

    get firstDayNumber(): number {
        let maxDay = (this.displayWeekend ? 7 : 5);
        for (let i = 1; i <= maxDay; i++) {
            if (this.displayedTimesheet.month + '' === this.datePipe.transform(this.week[i].date, 'M')) {
                return +this.datePipe.transform(this.week[i].date, 'd');
            }
        }
        for (let i = 6; i <= 7; i++) {
            if (this.displayedTimesheet.month + '' === this.datePipe.transform(this.week[i].date, 'M')) {
                return +this.datePipe.transform(this.week[i].date, 'd');
            }
        }
        return -1;
    }

    get lastDayNumber(): number {
        for (let i = (this.displayWeekend ? 7 : 5); i > 0; i--) {
            if (this.displayedTimesheet.month + '' === this.datePipe.transform(this.week[i].date, 'M')) {
                return +this.datePipe.transform(this.week[i].date, 'd');
            }
        }
        for (let i = 7; i > 5; i--) {
            if (this.displayedTimesheet.month + '' === this.datePipe.transform(this.week[i].date, 'M')) {
                return +this.datePipe.transform(this.week[i].date, 'd');
            }
        }
        return -1;
    }

    get isOneDayWeek(): boolean {
        return this.lastDayNumber === this.firstDayNumber;
    }

    loadCurrentWeek(event) {
        this.initialLoading.emit();
    }

    loadPreviousWeek(event) {
        this.clickPrevious.emit(event);
    }

    loadNextWeek(event) {
        this.clickNext.emit(event);
    }
}
