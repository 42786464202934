import {inject} from '@angular/core';
import {CanLoadFn, Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {filter, map} from 'rxjs/operators';
import {SpuradoState} from '@app/state/spurado.state';

export const isAdmin: CanLoadFn =
    () => {
        const router = inject(Router);
        return inject(Store).select(SpuradoState.account)
            .pipe(
                filter(a => !!a),
                map(a => a.isAdmin || router.createUrlTree(['..']))
            );
    }
