import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {Observable, of, Subject} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {UserService} from '@app/service/user.service';
import {ApplicationDetails} from '@app/spurado';
import {SpuradoState} from '@app/state/spurado.state';
import {AsyncPipe, NgFor, NgIf} from "@angular/common";
import {MatIcon} from "@angular/material/icon";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatButton} from "@angular/material/button";

@Component({
    selector: 'spurado-footer',
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf,
        NgFor,
        TranslateModule,
        MatIcon,
        MatButton,
        MatMenu,
        MatMenuItem,
        MatMenuTrigger,
    ],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent implements OnInit, OnDestroy {

    private unsubscribeSubject = new Subject<void>();
    applicationDetails$: Observable<ApplicationDetails> = this.store.select(SpuradoState.applicationDetails)
        .pipe(catchError(() => of({version: '?'})));

    get availableLangs(): string[] {
        return this.translateService.getLangs();
    }

    get currentLang(): string {
        return this.translateService.currentLang;
    }

    constructor(
        private translateService: TranslateService,
        private dateAdapter: DateAdapter<Date>,
        private userService: UserService,
        private store: Store,
    ) {
    }

    ngOnInit() {
        this.store.select(SpuradoState.user)
            .pipe(takeUntil(this.unsubscribeSubject))
            .subscribe(user => {
                if (user?.locale) {
                    this.useLang(user.locale.toLowerCase());
                }
            });
    }

    useLang(lang): void {
        this.translateService.use(lang);
        this.dateAdapter.setLocale(lang + '-BE');
        this.userService.updateMyLocale(lang).subscribe();
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.unsubscribe();
    }
}
