import {ChangeDetectionStrategy, Component} from '@angular/core';
import {MatBottomSheet} from '@angular/material/bottom-sheet';
import {Store} from '@ngxs/store';
import {of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {CommonService} from '@api/common.service';
import {InfoMessage} from '@app/spurado';
import {SpuradoState} from '@app/state/spurado.state';
import {BottomSheetMessageComponent} from '@app/shared/bottom-sheet-message/bottom-sheet-message.component';
import { TranslateModule } from '@ngx-translate/core';
import { NgIf, AsyncPipe } from '@angular/common';

@Component({
    selector: 'spurado-message-info',
    templateUrl: './message-info.component.html',
    styleUrls: ['./message-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        TranslateModule,
        AsyncPipe,
    ],
})
export class MessageInfoComponent {

    messages$ = this.store.select(SpuradoState.account)
        .pipe(switchMap(account => account ? this.commonService.getMessageInfos(account.organisation.uuid) : of([])));


    constructor(
        private store: Store,
        private commonService: CommonService,
        private bottomSheet: MatBottomSheet,
    ) {
    }

    openBottomSheet(messages: InfoMessage[]): void {
        this.bottomSheet.open(BottomSheetMessageComponent, {data: {messages}});
    }
}
