import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import {Router} from '@angular/router';
import {Select, Store} from '@ngxs/store';
import {mergeMap, Observable, shareReplay, Subject} from 'rxjs';
import {filter, map, takeUntil, tap} from 'rxjs/operators';
import {OrganisationService} from '@api/organisation.service';
import {UserService} from '@app/service/user.service';
import {Organisation, User} from '@app/spurado';
import {SpuradoState} from '@app/state/spurado.state';
import {organisationNameValidator, OrganisationValidatorDirective} from './organisation-validator';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatDivider } from '@angular/material/divider';
import { MatActionList, MatListItem } from '@angular/material/list';
import { MatCard, MatCardHeader, MatCardTitle, MatCardContent } from '@angular/material/card';
import { NgIf, NgFor, AsyncPipe } from '@angular/common';

@Component({
    selector: 'spurado-organisation',
    templateUrl: './organisation.component.html',
    styleUrls: ['./organisation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        MatCard,
        MatCardHeader,
        MatCardTitle,
        MatCardContent,
        MatActionList,
        NgFor,
        MatListItem,
        MatDivider,
        MatAccordion,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatButton,
        MatIcon,
        TranslateModule,
        AsyncPipe,
    ],
})
export class OrganisationComponent implements OnInit, OnDestroy {

    private readonly unsubscribeSubject: Subject<void>;

    organisationFormGroup: FormGroup;

    @Select(SpuradoState.user) loggedUser$: Observable<User>;
    isUniqueOrganisation$: Observable<boolean>;
    organisations$: Observable<Organisation[]>;

    constructor(
        private router: Router,
        private organisationService: OrganisationService,
        private userService: UserService,
        private store: Store,
    ) {
        this.unsubscribeSubject = new Subject<void>();
        this.organisationFormGroup = new FormGroup<any>({
            organisationName: new FormControl<string>('', [Validators.required, Validators.maxLength(255)]),
            organisationUrl: new FormControl<string>('', [Validators.required, Validators.maxLength(255)], organisationNameValidator(organisationService))
        });
    }

    ngOnInit(): void {
        const applicationDetails$ = this.store.select(SpuradoState.applicationDetails)
            .pipe(
                takeUntil(this.unsubscribeSubject),
                shareReplay(),
            );

        this.isUniqueOrganisation$ = applicationDetails$.pipe(
            map(details => details?.uniqueOrganisationUuid),
            tap(uniqueOrganisationUuid => {
                if (!!uniqueOrganisationUuid) {
                    this.goToOrganisation(uniqueOrganisationUuid);
                }
            }),
            map(uniqueOrganisationUuid => !!uniqueOrganisationUuid)
        );

        this.organisations$ = applicationDetails$.pipe(
            mergeMap(() => this.store.select(SpuradoState.user)),
            filter(user => user && this.isValidUser()),
            mergeMap(() => this.organisationService.getMyOrganisations()),
            map(organisations => {
                const spuradoLastOrgUuidUsed = window.localStorage.getItem('spuradoLastOrgUuidUsed');
                if (spuradoLastOrgUuidUsed && organisations?.map(o => o.uuid).indexOf(spuradoLastOrgUuidUsed) != -1) {
                    this.router.navigate(['/' + spuradoLastOrgUuidUsed]);
                }

                return organisations;
            })
        );

        this.organisationFormGroup.controls.organisationName.valueChanges.subscribe(
            value => this.organisationFormGroup.controls.organisationUrl.setValue(this.generateUuid(value))
        );
    }

    goToOrganisation(orgUuid: string): void {
        window.localStorage.setItem('spuradoLastOrgUuidUsed', orgUuid);
        this.router.navigate(['/' + orgUuid]);
    }

    isValidUser(): boolean {
        return this.userService.isValidUser();
    }

    generateUuid(name: string): string {
        return OrganisationValidatorDirective.stripAccents(name).toLowerCase().replace(/[^a-z0-9]+/g, '-');
    }

    createOrganisation(): void {
        this.organisationService.createOrganisation(this.getOrganisationName(), this.getOrganisationUuid()).subscribe(response => {
            this.router.navigate(['/' + this.getOrganisationUuid()]);
        });
    }

    getOrganisationName() {
        return this.organisationFormGroup.value.organisationName;
    }

    getOrganisationUuid() {
        return this.organisationFormGroup.value.organisationUrl;
    }

    ngOnDestroy() {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.unsubscribe();
    }
}
