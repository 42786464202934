import {Injectable} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {Store} from '@ngxs/store';
import {Observable, of} from 'rxjs';
import {filter, switchMap} from 'rxjs/operators';
import {AccountActions} from '@app/state/spurado.actions';

@Injectable({
    providedIn: 'root'
})
export class AppInitService {

    orgUuid$: Observable<string>;
    private lastOrgUuid: string;

    constructor(
        private router: Router,
        private store: Store,
    ) {
        this.orgUuid$ = this.router.events.pipe(
            filter(event => (event instanceof NavigationStart)),
            switchMap((event: NavigationStart) => {
                // A little bit hacky but as the service runs independently of the components, the route is not mapped
                // yet in the ActivatedRoute object on a reload event.
                return of(event.url.split('/')[1]);
            })
        );

        this.orgUuid$.subscribe((orgUuid) => {
            if (orgUuid && this.lastOrgUuid !== orgUuid) {
                this.lastOrgUuid = orgUuid;
                this.store.dispatch(new AccountActions.Load(orgUuid));
            }
        });
    }
}
