import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Store} from '@ngxs/store';
import {OAuthService} from 'angular-oauth2-oidc';
import {EMPTY, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {Account, SupportedLocale, User, UserLocaleUpdate} from '@app/spurado';
import {AccountActions, PendingApprovalsActions, PendingTasksActions, UserActions} from '@app/state/spurado.actions';
import {ImpersonalOAuthService} from '@app/service/impersonal-oauth.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private apiUrl: string = environment.apiUrl;

    constructor(
        private httpClient: HttpClient,
        private oauthService: OAuthService,
        private store: Store,
    ) {
    }

    async logout(connectedUser?: User, orgUuid?: string, account?: Account): Promise<void> {
        if (!connectedUser || !account.impersonated) {
            return this.oauthService.logOut();
        }

        await (this.oauthService as ImpersonalOAuthService).finishImpersonation();

        this.store.dispatch([
            new AccountActions.Load(orgUuid),
            new UserActions.Load(),
            new PendingApprovalsActions.Load(orgUuid),
            new PendingTasksActions.Load(orgUuid),
        ]);
    }

    impersonateAccount(accountUuid: string, orgUuid: string): Observable<any> {
        return this.httpClient.post(this.apiUrl + `${orgUuid}/accounts/${accountUuid}/impersonation`,
            {responseType: 'text'}, {responseType: 'text'})
            .pipe(tap(
                jwt => {
                    (this.oauthService as ImpersonalOAuthService).impersonate(jwt);
                })
            );
    }

    public isValidUser(): boolean {
        return this.oauthService.hasValidAccessToken();
    }

    updateMyLocale(locale: string): Observable<any> {
        const newLocale: SupportedLocale | undefined = (SupportedLocale as any)[locale.toUpperCase()];
        if (newLocale !== undefined) {
            const body = new UserLocaleUpdate();
            body.locale = newLocale;
            return this.httpClient.put(this.apiUrl + `users/locale`, body);
        } else {
            console.error('Argh ! Wrong input for locale \'' + locale + '\'');
            return EMPTY;
        }
    }

    refreshToken() {
        this.oauthService.silentRefresh().then(

        );
    }
}
