// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
  display: flex;
  gap: 16px;
  margin-top: 0;
}

.readonly-comment-text {
  font-style: italic;
  color: #3d3d3d;
  white-space: pre-wrap;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/timesheet/comment/timesheet-comment.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,SAAA;EACA,aAAA;AACJ;;AAEA;EACI,kBAAA;EACA,cAAA;EACA,qBAAA;AACJ","sourcesContent":["h2 {\n    display: flex;\n    gap: 16px;\n    margin-top: 0;\n}\n\n.readonly-comment-text {\n    font-style: italic;\n    color: #3d3d3d;\n    white-space: pre-wrap;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
