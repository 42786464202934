import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Task} from '@app/spurado';
import {HttpClient, HttpParams} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class TaskService {

    private apiUrl: string = environment.apiUrl;

    constructor(private httpClient: HttpClient) {
    }

    getAllTasks(orgUuid: string): Observable<Task[]> {

        return this.httpClient.get<Task[]>(this.apiUrl + `${orgUuid}/tasks`);
    }

    getPendingTasks(orgUuid: string): Observable<Task[]> {
        return this.httpClient.get<Array<Task>>(this.apiUrl + `${orgUuid}/tasks/pending`);
    }

    getNumberOfPendingTasks(orgUuid: string): Observable<number> {
        return this.httpClient.get<number>(this.apiUrl + `${orgUuid}/tasks/pending/count`);
    }

    getNumberOfActiveTasks(orgUuid: string): Observable<number> {
        return this.httpClient.get<number>(this.apiUrl + `${orgUuid}/tasks/active/count`);
    }

    getTasksForTimesheet(orgUuid: string, timesheetUuid?: string, forceAll?: boolean, filter?: string): Observable<Task[]> {
        let requestParams = new HttpParams();

        if (timesheetUuid && timesheetUuid.length > 0) {
            requestParams = requestParams.set('timesheetUuid', timesheetUuid);
        }

        if (forceAll) {
            requestParams = requestParams.set('forceAll', 'true');
        }

        if (filter && filter.length >= 3) {
            requestParams = requestParams.append('filter', filter);
        }
        return this.httpClient.get<Task[]>(this.apiUrl + `${orgUuid}/tasks/mine`, {params: requestParams});
    }

}
