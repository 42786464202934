// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host.overlay, :host.full-screen {
  display: grid;
  place-content: center;
  width: 100%;
  height: 100%;
}
:host.full-screen {
  position: fixed;
  inset: 0;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.05);
}
:host.inline {
  display: inline-block;
  height: 1.5rem;
  width: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/loader/loader.component.scss"],"names":[],"mappings":"AACI;EACI,aAAA;EACA,qBAAA;EACA,WAAA;EACA,YAAA;AAAR;AAGI;EACI,eAAA;EACA,QAAA;EACA,aAAA;EACA,+BAAA;AADR;AAII;EACI,qBAAA;EACA,cAAA;EACA,aAAA;AAFR","sourcesContent":[":host {\n    &.overlay, &.full-screen {\n        display: grid;\n        place-content: center;\n        width: 100%;\n        height: 100%;\n    }\n\n    &.full-screen {\n        position: fixed;\n        inset: 0;\n        z-index: 9999;\n        background: rgba(0, 0, 0, 0.05);\n    }\n\n    &.inline {\n        display: inline-block;\n        height: 1.5rem;\n        width: 1.5rem;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
