import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {Observable} from 'rxjs';
import {Organisation, User} from '@app/spurado';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class OrganisationService {

    apiUrl: string = environment.apiUrl + 'organisations';

    constructor(private httpClient: HttpClient) {
    }

    getMyOrganisations(): Observable<Array<Organisation>> {
        return this.httpClient.get<Array<User>>(this.apiUrl + `/mine`);
    }

    createOrganisation(organisationName: string, organisationUuid: string): Observable<any> {
        if (organisationName && organisationUuid && organisationName.length > 0 && organisationUuid.length > 0) {
            const newOrg = new Organisation();
            newOrg.name = organisationName;
            newOrg.uuid = organisationUuid;
            return this.httpClient.post(this.apiUrl, newOrg);
        }
    }

    isUuidAvailable(uuid: string): Observable<boolean> {
        if (uuid && uuid.length > 0) {
            return this.httpClient.get<boolean>(this.apiUrl + `/${uuid}/available`);
        }
    }

    updateOrganisationInfo(uuid: string, organisation: Organisation): Observable<any> {
        return this.httpClient.put<any>(this.apiUrl + `/${uuid}/info`, organisation);
    }

}

