import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Router, RouterLink, RouterLinkActive} from '@angular/router';
import {Select, Store} from '@ngxs/store';
import {Observable, shareReplay, Subject} from 'rxjs';
import {map, takeUntil, tap} from 'rxjs/operators';
import {AccountDelegationService} from '@api/account-delegation.service';
import {AccountService} from '@api/account.service';
import {BreakpointService} from '@app/service/breakpoint.service';
import {UserService} from '@app/service/user.service';
import {Account, AccountDelegation, SystemGroup, User} from '@app/spurado';
import {AccountActions, PendingApprovalsActions, PendingTasksActions, UserActions} from '@app/state/spurado.actions';
import {SpuradoState} from '@app/state/spurado.state';
import {AsyncPipe, NgFor, NgIf} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";
import {MatIcon} from "@angular/material/icon";
import {MatBadge} from "@angular/material/badge";
import {MatTooltip} from "@angular/material/tooltip";
import {MatIconButton} from "@angular/material/button";
import {MatMenu, MatMenuItem, MatMenuTrigger} from "@angular/material/menu";
import {MatDivider} from "@angular/material/divider";

@Component({
    selector: 'spurado-header',
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf,
        NgFor,
        RouterLink,
        RouterLinkActive,
        TranslateModule,
        MatIcon,
        MatIconButton,
        MatBadge,
        MatDivider,
        MatMenu,
        MatMenuItem,
        MatMenuTrigger,
        MatTooltip,
    ],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {

    private unsubscribeSubject = new Subject<void>();

    loggedAccount$: Observable<Account>;
    loggedAccount: Account;
    accountDelegations: AccountDelegation[] = [];
    @Select(SpuradoState.user) loggedUser$: Observable<User>;
    @Select(SpuradoState.pendingTasksCount) numberOfPendingTasks$: Observable<number>;
    @Select(SpuradoState.pendingApprovalsCount) numberOfPendingApprovals$: Observable<number>;
    pdfTimesheet$: Observable<boolean>;
    reporter$: Observable<boolean>;
    impersonator: boolean;
    expanded: boolean;

    identity$: Observable<string>;
    canEditTimesheets$: Observable<boolean>;
    canEditStatistics$: Observable<boolean>;

    constructor(
        public breakpointService: BreakpointService,
        private accountService: AccountService,
        private router: Router,
        private userService: UserService,
        private accountDelegationService: AccountDelegationService,
        private store: Store,
    ) {
        this.loggedAccount$ = this.store.select(SpuradoState.account)
            .pipe(
                shareReplay(),
                tap(account => this.loggedAccount = account)
            );
        this.reporter$ = this.loggedAccount$.pipe(
            map((account) => account?.organisation.configuration?.enableReports?.value)
        );
        this.canEditTimesheets$ = this.loggedAccount$.pipe(
            map((account) => account?.organisation.configuration?.enableAdminTimesheets?.value)
        );
        this.canEditStatistics$ = this.loggedAccount$.pipe(
            map((account) => account?.organisation.configuration?.enableAdminStatistics?.value)
        );
        this.pdfTimesheet$ = this.loggedAccount$.pipe(
            map((account) => account?.organisation.configuration?.enablePdfTimesheet?.value))
        ;
        this.identity$ = this.loggedAccount$.pipe(
            map((account) => this.accountService.accountToString(account))
        );
    }

    ngOnInit(): void {
        this.loggedAccount$
            .pipe(
                takeUntil(this.unsubscribeSubject),
                map(account => !!account?.groups.find(g => g.name === SystemGroup.SUPER_IMPERSONATOR))
            )
            .subscribe(isImpersonator => {
                this.impersonator = isImpersonator;
            });
    }

    onOpenMenu() {
        this.accountDelegationService.getDelegatorsSummaries(this.loggedAccount.organisation.uuid).subscribe((accountDelegations: AccountDelegation[]) => {
            this.accountDelegations = accountDelegations;
        });
    }

    canImpersonate(): boolean {
        if (!this.loggedAccount || this.loggedAccount.impersonated) {
            return false;
        }

        return this.impersonator || (this.loggedAccount.organisation.configuration.enableDelegation.value && this.accountDelegations.length > 0);
    }

    impersonate(account: Account, delegation: AccountDelegation) {
        const orgUuid = account.organisation.uuid;
        const newRoute = '/' + orgUuid;
        this.router.navigate([newRoute]).then(() => {
            this.userService.impersonateAccount(delegation.delegatorUuid, account.organisation.uuid).subscribe(
                () => {
                    this.store.dispatch([
                        new AccountActions.Load(orgUuid),
                        new UserActions.Load(),
                        new PendingApprovalsActions.Load(orgUuid),
                        new PendingTasksActions.Load(orgUuid),
                    ]);
                });
        });
    }

    async logout(loggedUser?: User, orgUuid?: string) {
        await this.userService.logout(loggedUser, orgUuid, this.loggedAccount);
        await this.router.navigate(['/']);
    }

    selectOrganisation() {
        window.localStorage.removeItem('spuradoLastOrgUuidUsed');
        void this.router.navigate(['/' + 'index.html']);
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.unsubscribe();
    }
}
