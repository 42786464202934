import {Theme} from '@app/spurado';

export namespace AccountActions {
    export class Load {
        static readonly type = '[Account] Load';

        constructor(public uuid: string) {
        }
    }
}

export namespace PendingApprovalsActions {
    export class Load {
        static readonly type = '[PendingApprovals] Load';

        constructor(public orgUuid: string) {
        }
    }
}

export namespace PendingTasksActions {
    export class Load {
        static readonly type = '[PendingTasks] Load';

        constructor(public orgUuid: string) {
        }
    }
}

export namespace ThemeActions {
    export class Load {
        static readonly type = '[ThemeActions] Load';

        constructor(public theme: Theme) {
        }
    }
}

export namespace UserActions {
    export class Load {
        static readonly type = '[User] Load';
    }
}

export namespace ApplicationDetailsActions {
    export class Load {
        static readonly type = '[ApplicationDetails] Load';
    }
}

export namespace SpuradoActions {
    export class Reset {
        static readonly type = '[Spurado] Reset';
    }
}
