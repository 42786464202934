import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {LoaderComponent, LoaderMode} from "./loader.component";

@Directive({
    selector: '[spuradoLoader]',
    standalone: true,
})
export class LoaderDirective {

    private diameter: number;
    private loading: boolean;
    private loader: LoaderComponent;
    private mode: LoaderMode = 'overlay';

    @Input()
    set spuradoLoader(loading: boolean) {
        if (loading === this.loading) {
            return;
        }

        this.loader = null;
        this.viewContainer.clear();
        this.loading = loading;

        if (!loading) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.showSpinner();
        }
    }

    @Input()
    set spuradoLoaderDiameter(diameter: number) {
        this.diameter = diameter;
        if (this.loader) {
            this.loader.diameter = diameter;
        }
    }

    @Input()
    set spuradoLoaderMode(mode: LoaderMode) {
        this.mode = mode;
        if (this.loader) {
            this.loader.mode = mode;
        }
    }


    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
    ) {
    }

    private showSpinner() {
        const {instance} = this.viewContainer.createComponent(LoaderComponent);
        instance.mode = this.mode;
        instance.diameter = this.diameter;
        this.loader = instance;
    }
}
