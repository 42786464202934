import { Routes } from '@angular/router';
import {OrganisationComponent} from "./shared/organisation/organisation.component";
import {OopsComponent} from "./shared/oops/oops.component";
import {RouterComponent} from "./shared/router/router.component";
import {HomeComponent} from "./home/home.component";
import {isAdmin} from "./strategy/security.guards";

export const routes: Routes = [
    {path: '', component: OrganisationComponent},
    {path: 'index.html', component: OrganisationComponent},
    {path: 'errors/oops', component: OopsComponent},
    {
        path: ':orgUuid',
        component: RouterComponent,
        children: [
            {path: '', component: HomeComponent},
            {
                path: 'account',
                loadChildren: () => import('./account/routes'),
            },
            {
                path: 'approval',
                loadChildren: () => import('./approval/routes'),
            },
            {path: 'reporting', loadComponent: () => import('./report/report.component')},
            {path: 'timesheet2pdf', loadComponent: () => import('./timesheet-pdf/timesheet-pdf-generation.component')},
            {
                path: 'admin',
                loadChildren: () => import('./admin/routes'),
                canLoad: [isAdmin]
            },
            {path: '**', redirectTo: 'errors/oops'} // Should be the last one
        ]
    },
    {path: '**', redirectTo: 'errors/oops'} // Should be the last one
];
