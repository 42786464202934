// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.daily-pointing-container {
  -webkit-user-select: none;
          user-select: none;
}

.daily-pointing-icon-desktop {
  vertical-align: -20px;
}

.daily-pointing-icon-mobile {
  margin-left: 10px;
}

.daily-pointing-icon-active {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/timesheet/daily-pointing-btn/timesheet-daily-pointing-btn.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;UAAA,iBAAA;AACJ;;AAEA;EACI,qBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":[".daily-pointing-container {\n    user-select: none;\n}\n\n.daily-pointing-icon-desktop {\n    vertical-align: -20px;\n}\n\n.daily-pointing-icon-mobile {\n    margin-left: 10px;\n}\n\n.daily-pointing-icon-active {\n    cursor: pointer;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
