import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {InfoMessage} from '@app/spurado';
import {MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';
import {TranslateService} from '@ngx-translate/core';
import { MatList, MatListItem } from '@angular/material/list';
import { NgFor } from '@angular/common';

@Component({
    selector: 'spurado-bottom-sheet-message',
    templateUrl: './bottom-sheet-message.component.html',
    styleUrls: ['./bottom-sheet-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgFor,
        MatList,
        MatListItem,
    ],
})
export class BottomSheetMessageComponent {

    constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: { messages: InfoMessage[] },
                private translateService: TranslateService) {
    }

    getLocalizedContent(message: InfoMessage): string {
        if ('nl' === this.translateService.currentLang) {
            return message.contentNl;
        }
        return message.contentFr;
    }
}
