import {ApplicationConfig, importProvidersFrom, provideZoneChangeDetection} from '@angular/core';
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {provideAnimationsAsync} from "@angular/platform-browser/animations/async";
import {provideMomentDateAdapter} from "@angular/material-moment-adapter";
import {provideRouter} from '@angular/router';
import {provideOAuthClient} from "angular-oauth2-oidc";

import {routes} from './app.routes';
import {appInitializerProvider} from "./app.initializer";
import {oauthClientConfig, oauthServiceProvider} from "./config/oauth.config";
import {ngxsProviders} from "./config/ngxs.providers";
import {i18nProviders} from "./config/i18n.providers";
import {matDateFormats, matPaginatorIntlProvider} from "./config/material.config";
import {l10nProviders} from "./config/l10n.providers";
import {fileSaverProvider} from "./config/file-saver.provider";

export const appConfig: ApplicationConfig = {
    providers: [
        provideHttpClient(
            /* we need to inject interceptors from DI because
             * provideOAuthClient() is providing interceptors with env-providers
             */
            withInterceptorsFromDi()
        ),
        provideOAuthClient(oauthClientConfig),
        oauthServiceProvider,
        provideZoneChangeDetection({eventCoalescing: true}),
        provideAnimationsAsync(),
        importProvidersFrom(...ngxsProviders),
        importProvidersFrom(...i18nProviders),
        ...l10nProviders,
        provideMomentDateAdapter(matDateFormats),
        matPaginatorIntlProvider,
        appInitializerProvider,
        fileSaverProvider,
        provideRouter(routes),
    ]
};
