import {LOCALE_ID} from "@angular/core";

export class DynamicLocaleId extends String {
    locale = 'fr-BE';

    toString() {
        return this.locale;
    }
}

export const l10nProviders = [
    {
        provide: LOCALE_ID,
        useClass: DynamicLocaleId
    }
];
