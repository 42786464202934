import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngxs/store';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BreakpointService} from '@app/service/breakpoint.service';
import {SnackbarService} from '@app/service/snackbar.service';
import {TimesheetService} from '@app/service/timesheet.service';
import {Account, Timesheet, TimesheetMode} from '@app/spurado';
import {SpuradoState} from '@app/state/spurado.state';
import { MobileTimesheetTableComponent } from './table/mobile/mobile-timesheet-table.component';
import { TimesheetTableComponent } from './table/desktop/timesheet-table.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { TimesheetStatusComponent } from './status/timesheet-status.component';
import { WeekNavigatorComponent } from '@app/shared/week-navigator/week-navigator.component';
import { LoaderDirective } from '@app/shared/loader/loader.directive';
import {getBooleanValueFromStorage} from "@app/utils/storage-value";

@Component({
    selector: 'spurado-timesheet',
    templateUrl: './timesheet.component.html',
    styleUrls: ['./timesheet.component.scss'],
    standalone: true,
    imports: [LoaderDirective, WeekNavigatorComponent, TimesheetStatusComponent, NgIf, TimesheetTableComponent, MobileTimesheetTableComponent, AsyncPipe]
})
export class TimesheetComponent implements OnInit, OnDestroy {

    @Input()
    mode: TimesheetMode;

    private unsubscribeSubject: Subject<void>;

    loggedAccount: Account;
    timesheet: Timesheet;
    initialWeek: Timesheet;
    loadingTimesheet = true;
    displayWeekend: boolean;

    constructor(
        private timesheetService: TimesheetService,
        private snackbarService: SnackbarService,
        public breakpointService: BreakpointService,
        private store: Store,
    ) {
        this.unsubscribeSubject = new Subject<void>();
        this.displayWeekend = getBooleanValueFromStorage('displayWeekEnd');
    }

    ngOnInit() {
        this.store.select(SpuradoState.account)
            .pipe(takeUntil(this.unsubscribeSubject))
            .subscribe(account => {
                if (account) {
                    this.loggedAccount = account;
                    this.getTimesheetForCurrentWeek();
                }
            });

        this.timesheetService.timesheetUnderRefresh
            .pipe(takeUntil(this.unsubscribeSubject))
            .subscribe(reloading => {
                this.loadingTimesheet = reloading;
            });

        this.timesheetService.refresh
            .pipe(takeUntil(this.unsubscribeSubject))
            .subscribe(() => this.refreshDataForSelectedWeek());
    }

    getTimesheetForCurrentWeek() {
        this.timesheetService.lockForReload();
        this.timesheetService.getTimeSheetForCurrentWeek(this.loggedAccount.organisation.uuid).subscribe(
            response => {
                this.timesheet = this.initialWeek = response;
                this.displayWeekend = this.timesheetService.toggleWeekendIfNeeded(this.timesheet);
            },
            () => {
                this.snackbarService.openErrorSnackBar();
            }
        );
    }

    getTimesheetForNextWeek() {
        this.timesheetService.lockForReload();
        this.timesheetService.getTimesheetForWeek(this.loggedAccount.organisation.uuid, this.timesheet.nextWeek.year, this.timesheet.nextWeek.month, this.loggedAccount.uuid, this.mode, this.timesheet.nextWeek.weekNumber).subscribe(
            response => {
                if (response.length === 1) {
                    this.timesheet = response[0];
                    this.displayWeekend = this.timesheetService.toggleWeekendIfNeeded(this.timesheet);
                } else {
                    this.snackbarService.openErrorSnackBar();
                }
            },
            () => {
                this.snackbarService.openErrorSnackBar();
            });
    }

    getTimesheetForPrevioustWeek() {
        this.timesheetService.lockForReload();
        this.timesheetService.getTimesheetForWeek(this.loggedAccount.organisation.uuid, this.timesheet.previousWeek.year, this.timesheet.previousWeek.month, this.loggedAccount.uuid, this.mode, this.timesheet.previousWeek.weekNumber).subscribe(
            response => {
                if (response.length === 1) {
                    this.timesheet = response[0];
                    this.displayWeekend = this.timesheetService.toggleWeekendIfNeeded(this.timesheet);
                } else {
                    this.snackbarService.openErrorSnackBar();
                }
            },
            () => {
                this.snackbarService.openErrorSnackBar();
            });
    }

    refreshDataForSelectedWeek() {
        this.timesheetService.getTimesheetForWeek(this.loggedAccount.organisation.uuid, this.timesheet.year, this.timesheet.month, this.loggedAccount.uuid, this.mode, this.timesheet.weekNumber).subscribe(
            response => {
                if (response.length === 1) {
                    this.timesheet = response[0];
                    this.displayWeekend = this.timesheetService.toggleWeekendIfNeeded(this.timesheet);
                } else {
                    this.snackbarService.openErrorSnackBar();
                }
            },
            () => {
                this.snackbarService.openErrorSnackBar();
            });
    }

    updateDisplayWeekend(displayWeekend: boolean) {
        localStorage.setItem('displayWeekEnd', displayWeekend.toString());
        this.displayWeekend = displayWeekend;
    }

    ngOnDestroy() {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.unsubscribe();
    }
}
