import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {AddLine, Timesheet, TimesheetDay, TimesheetLine, TimesheetLineAuthorization, TimesheetMode, WeekSummary} from '@app/spurado';
import {BehaviorSubject, Observable,} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Mutex} from 'async-mutex';
import {finalize, map, tap} from 'rxjs/operators';
import {getBooleanValueFromStorage} from "@app/utils/storage-value";


@Injectable({
    providedIn: 'root'
})
export class TimesheetService {

    apiUrl: string = environment.apiUrl;
    mutex: Mutex = new Mutex();

    timesheetUnderRefresh: BehaviorSubject<boolean> = new BehaviorSubject(true);

    refresh: EventEmitter<any> = new EventEmitter<any>(false);

    constructor(private httpClient: HttpClient) {
    }

    getTimesheets(orgUuid: string, year: number, month: number, week: number, mode: TimesheetMode, accountUuid?: string): Observable<Timesheet[]> {
        let httpParams = new HttpParams()
        .set('year', String(year))
        .set('month', String(month))
        .set('weekNumber', String(week))
        .set('mode', mode);
        if (accountUuid) {
            console.log('adding account uuid' + accountUuid);
            httpParams = httpParams.set('accountUuid', accountUuid);
        }
        return this.httpClient.get<Timesheet[]>(`${this.apiUrl}${orgUuid}/timesheets`, {params: httpParams})
        .pipe(finalize(() => this.timesheetUnderRefresh.next(false)));
    }

    getTimeSheetForCurrentWeek(orgUuid: string): Observable<Timesheet> {
        return this.httpClient.get<Timesheet>(`${this.apiUrl}${orgUuid}/timesheets/current`)
        .pipe(finalize(() => this.timesheetUnderRefresh.next(false)));
    }

    getTimesheetForWeek(orgUuid: string,
                        year: number,
                        month: number,
                        accountUuid: string,
                        mode: TimesheetMode,
                        weekNumber?: number): Observable<Timesheet[]> {
        let requestParams = new HttpParams()
        .set('mode', mode.toString())
        .set('year', year.toString())
        .set('month', month.toString())
        .set('accountUuid', accountUuid);
        if (weekNumber !== undefined) {
            requestParams = requestParams.append('weekNumber', weekNumber.toString());
        }
        return this.httpClient.get<Timesheet[]>(`${this.apiUrl}${orgUuid}/timesheets`, {params: requestParams})
        .pipe(finalize(() => {
            this.timesheetUnderRefresh.next(false);
        }));
    }

    toggleWeekendIfNeeded(timesheet: Timesheet): boolean {
        let firstEditableDay: number;
        // show the week-end if the other days are not editable
        const values = Object.keys(timesheet.week).map(e => timesheet.week[e]);

        for (const day of values) {
            if (day.editable && !firstEditableDay) {
                firstEditableDay = day.number;
            }
        }

        return getBooleanValueFromStorage('displayWeekEnd') || (firstEditableDay === 6 || firstEditableDay === 7);
    }

    getTimesheetsForMonth(orgUuid: string, year: number, month: number, accountUuid: string, mode: TimesheetMode): Observable<Timesheet[]> {
        return this.getTimesheetForWeek(orgUuid, year, month, accountUuid, mode);
    }

    createNewTimesheetLine(orgUuid: string, timesheetUuid: string, linesToAdd: AddLine[], mode: TimesheetMode): Promise<TimesheetLine[]> {
        const httpParams = new HttpParams().set('mode', mode);
        // We need to run it exclusively to be sure the updates will come after.
        let body;
        if (linesToAdd) {
            body = linesToAdd;
        }
        this.lockForReload();
        return this.mutex.runExclusive(async () => {
            return await this.httpClient.post<TimesheetLine[]>(`${this.apiUrl}${orgUuid}/timesheets/${timesheetUuid}/lines`, body, {params: httpParams})
            .pipe(finalize(() => {
                this.askForReload();
            }))
            .toPromise();
        });
    }

    createTimesheetLinesFromFavorites(orgUuid: string, timesheetUuid: string, mode: TimesheetMode): Promise<TimesheetLine[]> {
        const httpParams = new HttpParams().set('mode', mode);
        // We need to run it exclusively to be sure the updates will come after.
        this.lockForReload();
        return this.mutex.runExclusive(async () => {
            return await this.httpClient.post<TimesheetLine[]>(`${this.apiUrl}${orgUuid}/timesheets/${timesheetUuid}/lines/favorites`, null, {params: httpParams})
            .pipe(finalize(() => {
                this.askForReload();
            }))
            .toPromise();
        });
    }

    updateActual(orgUuid: string, timesheetUuid: string, line: TimesheetLine, day: TimesheetDay, mode: TimesheetMode): Promise<TimesheetLine> {
        const httpParams = new HttpParams().set('mode', mode);
        return this.mutex.runExclusive(async () => {
            return await this.httpClient.patch<TimesheetLine>(
                `${this.apiUrl}${orgUuid}/timesheets/${timesheetUuid}/lines/${line.sequence}/days`,
                {version: line.version, days: [day]},
                {params: httpParams}
            ).pipe(tap(response => {
                line.version = response.version;
                line.status = response.status;
            })).toPromise();
        });
    }

    updateComment(orgUuid: string, timesheetUuid: string, line: TimesheetLine, mode: TimesheetMode, comment: string): Promise<TimesheetLine> {
        const httpParams = new HttpParams().set('mode', mode);
        return this.mutex.runExclusive(async () => {
            return await this.httpClient.put<TimesheetLine>(
                `${this.apiUrl}${orgUuid}/timesheets/${timesheetUuid}/lines/${line.sequence}/comment`,
                {version: line.version, comment},
                {params: httpParams}
            ).pipe(tap(response => {
                line.version = response.version;
            })).toPromise();
        });
    }

    updateClient(orgUuid: string, timesheetUuid: string, line: TimesheetLine, mode: TimesheetMode, clientUuid: string): Promise<TimesheetLine> {
        const httpParams = new HttpParams().set('mode', mode);
        return this.mutex.runExclusive(async () => {
            return await this.httpClient.put<TimesheetLine>(
                `${this.apiUrl}${orgUuid}/timesheets/${timesheetUuid}/lines/${line.sequence}/client`,
                {version: line.version, clientUuid},
                {params: httpParams}
            ).pipe(tap(response => {
                line.version = response.version;
            })).toPromise();
        });
    }

    validateTimesheetBeforeSubmission(orgUuid: string, timesheet: Timesheet): Promise<any> {
        return this.mutex.runExclusive(async () => {
            return await this.httpClient.get(
                `${this.apiUrl}${orgUuid}/timesheets/${timesheet.uuid}/valid`)
            .toPromise();
        });
    }

    submitTimesheetForApproval(orgUuid: string, timesheet: Timesheet): Promise<any> {
        this.lockForReload();
        return this.mutex.runExclusive(async () => {
            // On ne doit envoyer que la sequence et la version
            return await this.httpClient.put(
                `${this.apiUrl}${orgUuid}/timesheets/${timesheet.uuid}/status/submitted`,
                timesheet.timesheetLines)
            .pipe(finalize(() => this.askForReload()))
            .toPromise();
        });
    }

    summary(orgUuid: string, timesheetUuid: string): Observable<WeekSummary[]> {
        return this.httpClient.get<WeekSummary[]>(this.apiUrl + `${orgUuid}/timesheets/${timesheetUuid}/summary`);
    }

    lockForReload() {
        this.timesheetUnderRefresh.next(true);
    }

    askForReload() {
        this.refresh.emit();
    }

    recallTimesheet(orgUuid: string, timesheet: Timesheet, preventRefresh?: boolean): Promise<any> {
        this.lockForReload();
        return this.mutex.runExclusive(async () => {
            // On ne doit envoyer que la sequence et la version
            return await this.httpClient.put(`${this.apiUrl}${orgUuid}/timesheets/${timesheet.uuid}/status/recalled`, timesheet.timesheetLines)
            .pipe(finalize(() => {
                if (preventRefresh) {
                    this.timesheetUnderRefresh.next(false);
                } else {
                    this.askForReload();
                }
            }))
            .toPromise();
        });
    }

    deleteLine(orgUuid: string, timesheetUuid: string, mode: TimesheetMode, lineSequence: string): Promise<any> {
        const httpParams = new HttpParams().set('mode', mode);
        // We need to run it exclusively to be sure the updates will come after.
        this.lockForReload();
        return this.mutex.runExclusive(async () => {
            return await this.httpClient.delete<any>(
                `${this.apiUrl}${orgUuid}/timesheets/${timesheetUuid}/lines/${lineSequence}`, {params: httpParams})
            .pipe(finalize(() => {
                this.askForReload();
            }))
            .toPromise();
        });
    }

    actionButtonAvailable(line: TimesheetLine, lineAction: TimesheetLineAuthorization): Observable<boolean> {
        return this.timesheetUnderRefresh.asObservable().pipe(map(value => {
            return !value && (line.allowedActions.indexOf(lineAction) !== -1);
        }));
    }
}
