import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {BreakpointService} from '@app/service/breakpoint.service';
import {ClientService} from '@api/client.service';
import {CommonService} from '@api/common.service';
import {HolidayService} from '@api/holiday.service';
import {SnackbarService} from '@app/service/snackbar.service';
import {TimesheetCommonService} from '@app/service/timesheet-common.service';
import {TimesheetService} from '@app/service/timesheet.service';
import {Task, TimesheetDay, TimesheetLine, TimesheetLineAuthorization} from '@app/spurado';
import {BaseTimesheetTableComponent} from '../base-timesheet-table.component';
import { TimesheetDailyPointingBtnComponent } from '@app/shared/timesheet/daily-pointing-btn/timesheet-daily-pointing-btn.component';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { ChipComponent } from '@app/shared/chip/chip.component';
import { ChipListComponent } from '@app/shared/chip-list/chip-list.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, MatExpansionPanelActionRow } from '@angular/material/expansion';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { MatIcon } from '@angular/material/icon';
import { MatMiniFabButton, MatButton, MatIconButton } from '@angular/material/button';
import { MatCard, MatCardTitle, MatCardContent } from '@angular/material/card';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';

@Component({
    selector: 'spurado-mobile-timesheet-table',
    templateUrl: './mobile-timesheet-table.component.html',
    styleUrls: ['./mobile-timesheet-table.component.scss'],
    standalone: true,
    imports: [NgIf, MatCard, MatCardTitle, MatMiniFabButton, MatIcon, MatCardContent, MatChipListbox, NgFor, MatChipOption, MatMenuTrigger, MatMenu, MatMenuItem, MatAccordion, MatExpansionPanel, NgClass, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription, MatProgressBar, MatTooltip, ChipListComponent, ChipComponent, MatFormField, MatLabel, MatInput, TimesheetDailyPointingBtnComponent, MatExpansionPanelActionRow, MatButton, MatIconButton, TranslateModule, DatePipe]
})
export class MobileTimesheetTableComponent extends BaseTimesheetTableComponent implements OnInit {

    @ViewChild('taskInput') taskInput: ElementRef<HTMLInputElement>;

    selectedLines: TimesheetLine[];
    separatorKeysCodes: number[] = [ENTER, COMMA];
    locale: string;
    step: number;
    dataPerDay: Map<number, { line: TimesheetLine, task: Task, day: TimesheetDay }[]>;

    constructor(
        projectDialog: MatDialog,
        holidayService: HolidayService,
        timesheetService: TimesheetService,
        public timesheetCommonService: TimesheetCommonService,
        commonService: CommonService,
        breakpointService: BreakpointService,
        snackbarService: SnackbarService,
        clientService: ClientService,
        translateService: TranslateService,
        clientDialog: MatDialog,
        submissionConfirmationDialog: MatDialog,
        commentDialog: MatDialog,
        holidayDialog: MatDialog,
        store: Store,
    ) {
        super(timesheetService,
            timesheetCommonService,
            holidayService,
            commonService,
            breakpointService,
            snackbarService,
            clientService,
            translateService,
            clientDialog,
            submissionConfirmationDialog,
            commentDialog,
            holidayDialog,
            projectDialog,
            store);
        this.step = 0;
        this.dataPerDay = new Map<number, { line: TimesheetLine, task: Task, day: TimesheetDay }[]>();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.selectedLines = this.timesheet.timesheetLines;
        this.buildDaysList(this.timesheet);
        this.buildDataForDays();
    }

    openCommentBoxAllowed(line: TimesheetLine): boolean {
        const canView = line.allowedActions.findIndex(a => a === TimesheetLineAuthorization.VIEW_COMMENT) !== -1;
        const canEdit = line.allowedActions.findIndex(a => a === TimesheetLineAuthorization.EDIT_COMMENT) !== -1;
        return canView || canEdit;
    }

    isLineRemovable(line: TimesheetLine): boolean {
        return line.allowedActions.findIndex(a => a === TimesheetLineAuthorization.DELETE) !== -1;
    }

    isLineDuplicable(line: TimesheetLine): boolean {
        return line.allowedActions.findIndex(a => a === TimesheetLineAuthorization.DUPLICATE) !== -1;
    }

    openClientModalAllowed(line: TimesheetLine): boolean {
        return line.allowedActions.findIndex(a => a === TimesheetLineAuthorization.EDIT_CLIENT) !== -1;
    }

    removeLine(line: TimesheetLine) {
        this.timesheetService.deleteLine(this.loggedAccount.organisation.uuid, this.timesheet.uuid, this.mode, line.sequence)
            .catch(error => {
                    this.snackbarService.openErrorSnackBar(error);
                }
            );
    }

    setStep(step: number) {
        this.step = step;
    }

    prevStep() {
        this.step = this.step - 1;
    }

    nextStep() {
        this.step = 1 + (this.step % this.days.length);
    }

    private buildDataForDays(): void {
        this.timesheet.timesheetLines.forEach(line => {
            line.timesheetDays.forEach(timesheetDay => {
                if (this.dataPerDay.has(timesheetDay.number)) {
                    this.dataPerDay.get(timesheetDay.number).push({
                        line,
                        task: line.task,
                        day: timesheetDay
                    });
                } else {
                    this.dataPerDay.set(timesheetDay.number, [{
                        line,
                        task: line.task,
                        day: timesheetDay
                    }]);
                }
            });
        });
    }
}
