import {ChangeDetectionStrategy, Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import {Action, HolidayDuration, PersonalHoliday, WeekDay} from '@app/spurado';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatIcon } from '@angular/material/icon';
import { NgIf } from '@angular/common';

type HolidayDialogData = { day: WeekDay, mode: Action, encodedHours: number }

@Component({
    selector: 'spurado-timesheet-holiday',
    templateUrl: './timesheet-holiday.component.html',
    styleUrls: ['./timesheet-holiday.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        MatDialogTitle,
        MatDialogContent,
        MatIcon,
        MatCheckbox,
        ReactiveFormsModule,
        FormsModule,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        TranslateModule,
    ],
})
export class TimesheetHolidayComponent implements OnInit {

    am = false;
    pm = false;
    personalHoliday: PersonalHoliday = {};
    readonly mode: Action;

    actionMode = Action;
    private readonly encodedHours: number;
    private updatedDayStatus = false;

    constructor(
        public dialogRef: MatDialogRef<TimesheetHolidayComponent>,
        @Inject(MAT_DIALOG_DATA) public data: HolidayDialogData,
    ) {
        this.mode = data.mode;
        this.encodedHours = data.encodedHours;
    }

    ngOnInit() {
        if (this.mode === Action.ADD) {
            // Creating a new personalHoliday
            this.personalHoliday = new PersonalHoliday();
            this.personalHoliday.date = this.data.day.date;
        } else {
            // Editing an existing personalHoliday
            Object.assign(this.personalHoliday, this.data.day.personalHoliday);
            this.am = (this.personalHoliday.duration === HolidayDuration.AM || this.personalHoliday.duration === HolidayDuration.FD);
            this.pm = (this.personalHoliday.duration === HolidayDuration.PM || this.personalHoliday.duration === HolidayDuration.FD);
        }
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    updateDayStatus() {
        this.updatedDayStatus = (this.am !== (this.personalHoliday.duration === HolidayDuration.AM || this.personalHoliday.duration === HolidayDuration.FD)
            || (this.pm !== (this.personalHoliday.duration === HolidayDuration.PM || this.personalHoliday.duration === HolidayDuration.FD)));
        if (this.am && this.pm) {
            this.personalHoliday.duration = HolidayDuration.FD;
        } else if (this.am) {
            this.personalHoliday.duration = HolidayDuration.AM;
        } else if (this.pm) {
            this.personalHoliday.duration = HolidayDuration.PM;
        } else {
            this.personalHoliday.duration = null;
        }
    }

    shouldDisableConfirmButton(): boolean {
        return !this.updatedDayStatus;
    }

    shouldDisplayWarningMessage(): boolean {
        return (this.encodedHours !== 0 && this.am && this.pm);
    }
}
