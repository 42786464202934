import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Configuration} from '@app/spurado';
import { MatBadge } from '@angular/material/badge';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'spurado-timesheet-daily-pointing-btn',
    templateUrl: './timesheet-daily-pointing-btn.component.html',
    styleUrls: ['./timesheet-daily-pointing-btn.component.scss'],
    standalone: true,
    imports: [NgIf, MatIcon, NgClass, MatBadge]
})
export class TimesheetDailyPointingBtnComponent implements OnInit {

    @Input()
    configuration: Configuration;

    @Input()
    actualDuration: number;

    @Input()
    disabled: boolean;

    @Input()
    iconStyleClass: string;

    @Output()
    saveActual: EventEmitter<number> = new EventEmitter<number>();

    badge: number;

    constructor() {
    }

    ngOnInit(): void {
        if (this.actualDuration && this.actualDuration > 0 && this.configuration) {
            this.badge = this.actualDuration / this.configuration.workingHoursPerDay.value;
            if (this.badge < 1) {
                this.badge = 0.5;
            }
        }
    }

    next() {
        let newBadge: number;
        if (this.badge) {
            if (this.badge === 1) {
                newBadge = 0.5;
            } else {
                newBadge = undefined;
            }
        } else {
            newBadge = 1;
        }

        this.badge = newBadge;
        if (this.configuration) {
            if (!newBadge) {
                newBadge = 0;
            }
            this.saveActual.emit(newBadge * this.configuration.workingHoursPerDay.value);
        }
    }

}
