import { KeyValue, NgIf, NgClass, NgFor, AsyncPipe, DatePipe, KeyValuePipe } from '@angular/common';
import {AfterViewInit, Component, EventEmitter, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {BreakpointService} from '@app/service/breakpoint.service';
import {ClientService} from '@api/client.service';
import {CommonService} from '@api/common.service';
import {HolidayService} from '@api/holiday.service';
import {SnackbarService} from '@app/service/snackbar.service';
import {TimesheetCommonService} from '@app/service/timesheet-common.service';
import {TimesheetService} from '@app/service/timesheet.service';
import {Action, LineStatus, TimesheetDay, TimesheetLine, WeekDay} from '@app/spurado';
import {PendingApprovalsActions} from '@app/state/spurado.actions';
import {ConfirmationComponent} from '@app/shared/confirmation/confirmation.component';
import {BaseTimesheetTableComponent} from '../base-timesheet-table.component';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIconButton, MatButton, MatMiniFabButton } from '@angular/material/button';
import { TimesheetDailyPointingBtnComponent } from '@app/shared/timesheet/daily-pointing-btn/timesheet-daily-pointing-btn.component';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { ChipComponent } from '@app/shared/chip/chip.component';
import {FocusDirective} from '@app/shared/focus/focus.directive';

@Component({
    selector: 'spurado-timesheet-table',
    templateUrl: './timesheet-table.component.html',
    styleUrls: ['./timesheet-table.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        NgClass,
        NgFor,
        AsyncPipe,
        DatePipe,
        TranslateModule,
        MatIcon,
        MatTooltip,
        MatFormField,
        MatLabel,
        MatInput,
        MatIconButton,
        MatMenuTrigger,
        MatMenu,
        MatMenuItem,
        MatButton,
        MatMiniFabButton,
        ChipComponent,
        TimesheetDailyPointingBtnComponent,
        KeyValuePipe,
        FocusDirective,
    ]
})
export class TimesheetTableComponent extends BaseTimesheetTableComponent implements OnInit, AfterViewInit {

    actionMode = Action;
    public focusEventEmitter: EventEmitter<boolean>;

    constructor(
        breakpointService: BreakpointService,
        projectDialog: MatDialog,
        timesheetService: TimesheetService,
        timesheetCommonService: TimesheetCommonService,
        commonService: CommonService,
        snackbarService: SnackbarService,
        clientService: ClientService,
        clientDialog: MatDialog,
        holidayService: HolidayService,
        translateService: TranslateService,
        holidayDialog: MatDialog,
        commentDialog: MatDialog,
        submissionConfirmationDialog: MatDialog,
        store: Store,
    ) {
        super(
            timesheetService,
            timesheetCommonService,
            holidayService,
            commonService,
            breakpointService,
            snackbarService,
            clientService,
            translateService,
            clientDialog,
            submissionConfirmationDialog,
            commentDialog,
            holidayDialog,
            projectDialog,
            store
        );
        this.focusEventEmitter = new EventEmitter<boolean>(true);
    }

    deleteLineConfirmationModal(timesheetLine: TimesheetLine): void {
        const atLeastOneHourEncoded = timesheetLine.timesheetDays &&
            timesheetLine.timesheetDays.filter((t) => (t.actualDuration && t.actualDuration > 0)).length > 0;

        if (atLeastOneHourEncoded) {
            const submissionConfirmationRef = this.submissionConfirmationDialog.open(ConfirmationComponent, {
                disableClose: true,
                autoFocus: true
            });

            const subscription = submissionConfirmationRef.afterClosed().subscribe(result => {
                if (result) {
                    this.deleteLine(timesheetLine);
                }
                subscription.unsubscribe();
            });
        } else {
            this.deleteLine(timesheetLine);
        }

    }

    private deleteLine(timesheetLine: TimesheetLine): void {

        this.timesheetService.deleteLine(this.loggedAccount.organisation.uuid, this.timesheet.uuid, this.mode, timesheetLine.sequence)
            .then(() => {
                this.lineActionChange.emit({
                    timesheetUuid: this.timesheet.uuid,
                    line: timesheetLine,
                    action: Action.DELETE
                });
                if (timesheetLine.status !== LineStatus.OPEN) {
                    this.store.dispatch(new PendingApprovalsActions.Load(this.loggedAccount.organisation.uuid));
                }
            })
            .catch(() => {
                    this.snackbarService.openErrorSnackBar();
                }
            );
    }

    approveOrRejectLine(timesheetUuid: string, line: TimesheetLine, action: Action) {
        this.lineActionChange.emit({timesheetUuid, line, action});
    }

    compareNumbers = (a: KeyValue<number, number>, b: KeyValue<number, number>): number => {
        return a.key < b.key ? -1 : a.key === b.key ? 0 : 1;
    };

    getLastApprobatorOfLine(line: TimesheetLine) {
        if (line && line.lastApprobator && line.lastApprobator.displayName) {
            return line.lastApprobator.displayName;
        }
        return '';
    }

    isPendingApproval(lineStatus: LineStatus) {
        switch (lineStatus) {
            case LineStatus.PENDING_APPROVAL:
                return true;
            case LineStatus.OPEN:
            case LineStatus.VALIDATED:
                return false;
        }
    }

    isValidated(lineStatus: LineStatus) {
        switch (lineStatus) {
            case LineStatus.VALIDATED:
                return true;
            case LineStatus.OPEN:
            case LineStatus.PENDING_APPROVAL:
                return false;
        }
    }

    getCellClassForDay(line: TimesheetLine, day: TimesheetDay) {
        const dayTypeStyle = this.getCellClassForDayType(day);
        const lineStatusStyle = this.getCellClassForLineStatus(line);
        const dayStatusStyle = this.getCellClassForDayStatus(day);

        return dayTypeStyle + ' ' + lineStatusStyle + ' ' + dayStatusStyle;
    }

    private getCellClassForLineStatus(line: TimesheetLine) {
        switch (line.status) {
            case LineStatus.OPEN:
                return '';
            case LineStatus.PENDING_APPROVAL:
                return 'pending-approval';
            case LineStatus.VALIDATED:
                return 'validated';
        }
    }

    private getCellClassForDayType(day: TimesheetDay): string {
        const weekDay: WeekDay = this.timesheet.week[day.number];

        return weekDay.weekDay ? 'week' : 'week-end';
    }

    private getCellClassForDayStatus(day: TimesheetDay) {
        const weekDay: WeekDay = this.timesheet.week[day.number];
        if (weekDay.legalHoliday) {
            return 'legal-holiday';
        } else if (weekDay.personalHoliday) {
            return 'personal-holiday';
        } else {
            return '';
        }
    }

    getClassesForLine(line: TimesheetLine): string {
        let result = 'table-row';
        if (this.highlightedLineSequence && this.highlightedLineSequence === line.sequence) {
            result += ' highlightedTask';
        }
        return result;
    }

    ngAfterViewInit(): void {
        this.focusEventEmitter.emit(true);
    }


}
