// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  color: #ffffff;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.footer-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: center;
}

.footer-center {
  flex: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.version {
  font-size: 0.9em;
}

#copyright {
  font-size: 0.7rem;
}

.footer-right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: right;
  align-items: center;
}

.color-contrast {
  font-size: 1rem;
}

.color-contrast mat-icon {
  font-size: 1.5rem;
  width: 1.5rem;
  height: 1.5rem;
}`, "",{"version":3,"sources":["webpack://./src/app/layout/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,OAAA;EACA,aAAA;EACA,sBAAA;EACA,qBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,OAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,cAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AAEA;EACI,OAAA;EACA,aAAA;EACA,sBAAA;EACA,sBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,iBAAA;EACA,aAAA;EACA,cAAA;AACJ","sourcesContent":["footer {\n    color: #ffffff;\n    height: 100%;\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n    align-items: center;\n}\n\n.footer-left {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    justify-content: left;\n    align-items: center;\n}\n\n.footer-center {\n    flex: 5;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    margin: 0 10px;\n}\n\n.version {\n    font-size: 0.9em;\n}\n\n#copyright {\n    font-size: 0.7rem;\n}\n\n.footer-right {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    justify-content: right;\n    align-items: center;\n}\n\n.color-contrast {\n    font-size: 1rem;\n}\n\n.color-contrast mat-icon{\n    font-size: 1.5rem;\n    width: 1.5rem;\n    height: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
