// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.checkbox-group {
  margin: 20px 0;
  display: flex;
  flex-direction: column;
}

.encoded-hours-warning {
  display: flex;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
}
.encoded-hours-warning mat-icon {
  width: auto;
  flex-shrink: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/timesheet/holiday/timesheet-holiday.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,aAAA;EACA,sBAAA;AACJ;;AAEA;EACI,aAAA;EACA,mBAAA;EACA,aAAA;EACA,SAAA;AACJ;AACI;EACI,WAAA;EACA,cAAA;AACR","sourcesContent":[".checkbox-group {\n    margin: 20px 0;\n    display: flex;\n    flex-direction: column;\n}\n\n.encoded-hours-warning {\n    display: flex;\n    align-items: center;\n    padding: 1rem;\n    gap: 1rem;\n\n    mat-icon {\n        width: auto;\n        flex-shrink: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
