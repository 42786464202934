import {ModuleWithProviders} from "@angular/core";
import {NgxsModule} from "@ngxs/store";
import {NgxsReduxDevtoolsPluginModule} from "@ngxs/devtools-plugin";
import {environment} from 'src/environments/environment';
import {SpuradoState} from '@app/state/spurado.state';

// TODO: NGXS@18.0.0 has support for standalone projects, see documentation in order to replace this current configuration

export const ngxsProviders: ModuleWithProviders<any>[] = [
    NgxsModule.forRoot([SpuradoState], {
        developmentMode: !environment.production
    }),
    NgxsReduxDevtoolsPluginModule.forRoot({
        disabled: environment.production
    })
];
