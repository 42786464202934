import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {TimesheetValidation} from '@app/spurado';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { NgIf, NgFor, DatePipe } from '@angular/common';
import { LoaderDirective } from '@app/shared/loader/loader.directive';
import { MatLabel } from '@angular/material/form-field';

@Component({
    selector: 'spurado-timesheet-validation',
    templateUrl: './timesheet-validation.component.html',
    styleUrls: ['./timesheet-validation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        MatLabel,
        LoaderDirective,
        NgIf,
        MatIcon,
        NgFor,
        TranslateModule,
        DatePipe,
    ],
})
export class TimesheetValidationComponent {

    @Input()
    loading: boolean;
    @Input()
    timesheetValidation: TimesheetValidation;
}
