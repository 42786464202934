// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.organisation-panel {
  padding-top: 10px;
  margin: auto;
  max-width: 33%;
}

.organisation-container {
  margin-bottom: 2rem;
  padding: 2rem;
}

.organisation-form mat-form-field {
  width: 90%;
}

#add-button {
  width: -moz-fit-content;
  width: fit-content;
  margin-left: auto;
}

.login {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

@media (max-width: 1053px) {
  .organisation-panel {
    max-width: 50%;
  }
}
@media (max-width: 600px) {
  .organisation-panel {
    max-width: 80%;
  }
  .organisation-form {
    flex-direction: column;
    align-items: center;
  }
  .organisation-form mat-form-field {
    width: 90%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/organisation/organisation.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,YAAA;EACA,cAAA;AACJ;;AAEA;EACI,mBAAA;EACA,aAAA;AACJ;;AAGI;EACI,UAAA;AAAR;;AAIA;EACI,uBAAA;EAAA,kBAAA;EACA,iBAAA;AADJ;;AAIA;EACI,gBAAA;EACA,aAAA;EACA,uBAAA;AADJ;;AAIA;EACI;IACI,cAAA;EADN;AACF;AAIA;EACI;IACI,cAAA;EAFN;EAKE;IACI,sBAAA;IACA,mBAAA;EAHN;EAKM;IACI,UAAA;EAHV;AACF","sourcesContent":[".organisation-panel {\n    padding-top: 10px;\n    margin: auto;\n    max-width: 33%;\n}\n\n.organisation-container {\n    margin-bottom: 2rem;\n    padding: 2rem;\n}\n\n.organisation-form {\n    mat-form-field {\n        width: 90%;\n    }\n}\n\n#add-button {\n    width: fit-content;\n    margin-left: auto;\n}\n\n.login {\n    margin-top: 20px;\n    display: flex;\n    justify-content: center;\n}\n\n@media (max-width: 1053px) {\n    .organisation-panel {\n        max-width: 50%;\n    }\n}\n\n@media (max-width: 600px) {\n    .organisation-panel {\n        max-width: 80%;\n    }\n\n    .organisation-form {\n        flex-direction: column;\n        align-items: center;\n\n        mat-form-field {\n            width: 90%;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
