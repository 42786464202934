import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {AsyncPipe} from "@angular/common";
import {ActivatedRoute, Router, RouterOutlet} from '@angular/router';
import {Select, Store} from '@ngxs/store';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {Account} from '@app/spurado';
import {PendingApprovalsActions, PendingTasksActions, SpuradoActions} from '@app/state/spurado.actions';
import {SpuradoState} from '@app/state/spurado.state';
import {LoaderDirective} from '@app/shared/loader/loader.directive';

@Component({
    selector: 'spurado-router',
    standalone: true,
    imports: [
        AsyncPipe,
        RouterOutlet,
        LoaderDirective,
    ],
    templateUrl: './router.component.html',
    styleUrl: './router.component.scss'
})
export class RouterComponent implements OnInit, OnDestroy {

    private unsubscribeSubject: Subject<void>;
    @Select(SpuradoState.account) loggedAccount$: Observable<Account>;

    @HostBinding('class') componentCssClass;

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private store: Store,
    ) {
        this.unsubscribeSubject = new Subject<void>();
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap
            .pipe(takeUntil(this.unsubscribeSubject))
            .subscribe(paramMap => {
                if (paramMap.has('orgUuid')) {
                    const orgUuid = paramMap.get('orgUuid');
                    this.loggedAccount$
                        .pipe(takeUntil(this.unsubscribeSubject))
                        .subscribe((account: Account) => {
                            if (account) {
                                if (account.isAdmin) {
                                    this.store.dispatch(new PendingTasksActions.Load(orgUuid));
                                }
                                if (account.organisation.configuration && account.organisation.configuration.enableApprob.value) {
                                    this.store.dispatch(new PendingApprovalsActions.Load(orgUuid));
                                }
                            }
                        });
                } else {
                    this.router.navigate(['/errors/oops']);
                }
            });
    }

    ngOnDestroy(): void {
        this.store.dispatch(new SpuradoActions.Reset());
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.unsubscribe();
    }
}
