// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
    Styling for the week selector component
 */
#week-title {
  font-size: 1.2rem;
}

.week-selector-container {
  width: 90%;
  margin: 2rem auto;
}

.reset-week {
  margin: 5px;
}

.week-selector {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
.week-selector button {
  margin: 0 10px;
}

.week-selector > div {
  text-align: center;
  margin: 0 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/week-navigator/week-navigator.component.scss"],"names":[],"mappings":"AAAA;;EAAA;AAIA;EACI,iBAAA;AAAJ;;AAGA;EACI,UAAA;EACA,iBAAA;AAAJ;;AAGA;EACI,WAAA;AAAJ;;AAGA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAAJ;AAEI;EACI,cAAA;AAAR;;AAIA;EACI,kBAAA;EACA,cAAA;AADJ","sourcesContent":["/**\n    Styling for the week selector component\n */\n\n#week-title {\n    font-size: 1.2rem;\n}\n\n.week-selector-container {\n    width: 90%;\n    margin: 2rem auto;\n}\n\n.reset-week {\n    margin: 5px;\n}\n\n.week-selector {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    margin-top: 1rem;\n\n    button {\n        margin: 0 10px;\n    }\n}\n\n.week-selector > div {\n    text-align: center;\n    margin: 0 10px;\n}\n\n@media(max-width: 980px) {\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
