// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#mobile-timesheet-table-container {
  width: 90%;
  margin: auto;
}
#mobile-timesheet-table-container > mat-card {
  margin: 2rem 0;
}

#task-header {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#holiday-container {
  padding: 1rem 0;
}
#holiday-container button {
  display: block;
  margin-left: auto;
}

.project-autocomplete-form {
  width: 100%;
}

#mobile-progress-indicator {
  margin-left: auto;
  max-width: 200px;
  align-self: flex-end;
}

.mat-expansion-panel-header-title, .mat-expansion-panel-header-description {
  flex: 1;
}

#week-accordion {
  position: relative;
  z-index: 1;
}

.mobile-week-selector-container {
  width: 100%;
  text-align: center;
  transform: translateY(-15%);
}
.mobile-week-selector-container mat-icon {
  animation: bounce ease-in-out 2s infinite;
}

@keyframes bounce {
  0%, 100% {
    transform: translate(0, 5px);
  }
  50% {
    transform: translate(0, 0);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/timesheet/table/mobile/mobile-timesheet-table.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,YAAA;AACJ;AACI;EACI,cAAA;AACR;;AAGA;EACI,aAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,eAAA;AAAJ;AAEI;EACI,cAAA;EACA,iBAAA;AAAR;;AAIA;EACI,WAAA;AADJ;;AAIA;EACI,iBAAA;EACA,gBAAA;EACA,oBAAA;AADJ;;AAIA;EACI,OAAA;AADJ;;AAIA;EACI,kBAAA;EACA,UAAA;AADJ;;AAIA;EACI,WAAA;EACA,kBAAA;EACA,2BAAA;AADJ;AAGI;EACI,yCAAA;AADR;;AAKA;EACI;IACI,4BAAA;EAFN;EAIE;IACI,0BAAA;EAFN;AACF","sourcesContent":["#mobile-timesheet-table-container {\n    width: 90%;\n    margin: auto;\n\n    > mat-card {\n        margin: 2rem 0\n    }\n}\n\n#task-header {\n    padding: 1rem;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n#holiday-container {\n    padding: 1rem 0;\n\n    button {\n        display: block;\n        margin-left: auto;\n    }\n}\n\n.project-autocomplete-form {\n    width: 100%;\n}\n\n#mobile-progress-indicator {\n    margin-left: auto;\n    max-width: 200px;\n    align-self: flex-end;\n}\n\n.mat-expansion-panel-header-title, .mat-expansion-panel-header-description {\n    flex: 1;\n}\n\n#week-accordion {\n    position: relative;\n    z-index: 1;\n}\n\n.mobile-week-selector-container {\n    width: 100%;\n    text-align: center;\n    transform: translateY(-15%);\n\n    mat-icon {\n        animation: bounce ease-in-out 2s infinite;\n    }\n}\n\n@keyframes bounce {\n    0%, 100% {\n        transform: translate(0, 5px);\n    }\n    50% {\n        transform: translate(0, 0);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
