import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {environment} from 'src/environments/environment';
import {AccountService} from '@api/account.service';
import {ApprovalService} from '@api/approval.service';
import {CommonService} from '@api/common.service';
import {TaskService} from '@api/task.service';
import {Account, ApplicationDetails, Theme, User} from '@app/spurado';
import {
    AccountActions,
    ApplicationDetailsActions,
    PendingApprovalsActions,
    PendingTasksActions,
    SpuradoActions,
    ThemeActions,
    UserActions
} from './spurado.actions';

export type SpuradoStateModel = {
    applicationDetails?: ApplicationDetails,
    account?: Account,
    user?: User,
    theme?: Theme,
    pendingApprovalsCount?: number,
    pendingTasksCount?: number,
}

@State<SpuradoStateModel>({
    name: 'spurado',
    defaults: {
        applicationDetails: undefined,
        account: undefined,
        user: undefined,
        theme: Theme.NEUTRAL,
        pendingApprovalsCount: undefined,
        pendingTasksCount: undefined,
    }
})
@Injectable()
export class SpuradoState {

    constructor(
        private accountService: AccountService,
        private approvalService: ApprovalService,
        private commonService: CommonService,
        private http: HttpClient,
        private tasksService: TaskService,
    ) {
    }

    @Selector()
    static applicationDetails(state: SpuradoStateModel) {
        return state.applicationDetails;
    }

    @Selector()
    static account(state: SpuradoStateModel) {
        return state.account;
    }

    @Selector()
    static user(state: SpuradoStateModel) {
        return state.user;
    }

    @Selector()
    static theme(state: SpuradoStateModel) {
        return state.theme;
    }

    @Selector()
    static pendingApprovalsCount(state: SpuradoStateModel) {
        return state.pendingApprovalsCount;
    }

    @Selector()
    static pendingTasksCount(state: SpuradoStateModel) {
        return state.pendingTasksCount;
    }

    @Action(AccountActions.Load)
    loadAccount(ctx: StateContext<SpuradoStateModel>, action: AccountActions.Load) {
        this.accountService.getAccount(action.uuid)
            .subscribe(account => ctx.patchState({account}));
    }

    @Action(ApplicationDetailsActions.Load)
    loadApplicationDetails(ctx: StateContext<SpuradoStateModel>) {
        this.commonService.getApplicationDetails()
            .subscribe(applicationDetails => ctx.patchState({applicationDetails}));
    }

    @Action(PendingApprovalsActions.Load)
    loadPendingApprovalsActions(ctx: StateContext<SpuradoStateModel>, action: PendingApprovalsActions.Load) {
        this.approvalService.getNumberOfApprovals(action.orgUuid)
            .subscribe(pendingApprovalsCount => ctx.patchState({pendingApprovalsCount}));
    }

    @Action(PendingTasksActions.Load)
    loadPendingTasksActions(ctx: StateContext<SpuradoStateModel>, action: PendingTasksActions.Load) {
        this.tasksService.getNumberOfPendingTasks(action.orgUuid)
            .subscribe(pendingTasksCount => ctx.patchState({pendingTasksCount}));
    }

    @Action(ThemeActions.Load)
    loadTheme(ctx: StateContext<SpuradoStateModel>, action: ThemeActions.Load) {
        ctx.patchState({theme: action.theme});
    }

    @Action(UserActions.Load)
    loadUser(ctx: StateContext<SpuradoStateModel>) {
        this.http.get<User>(environment.apiUrl + `users/me`)
            .subscribe(user => ctx.patchState({user}));
    }

    @Action(SpuradoActions.Reset)
    resetAccount(ctx: StateContext<SpuradoStateModel>) {
        ctx.patchState({
            account: undefined,
            pendingApprovalsCount: undefined,
            pendingTasksCount: undefined,
            theme: Theme.NEUTRAL
        });
    }
}
