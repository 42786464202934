import {Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {AsyncPipe, NgIf} from "@angular/common";
import {OverlayContainer} from '@angular/cdk/overlay';
import {Router, RouterOutlet} from '@angular/router';
import {MatIcon} from "@angular/material/icon";
import {MatButton} from "@angular/material/button";
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Select, Store} from '@ngxs/store';
import {OAuthErrorEvent, OAuthService} from 'angular-oauth2-oidc';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {authConfig} from '@app/oidc/auth.config';
import {BreakpointService} from '@app/service/breakpoint.service';
import {CommonService} from '@api/common.service';
import {SnackbarService} from '@app/service/snackbar.service';
import {Account, ApplicationDetails, Theme, User} from '@app/spurado';
import {Criticity} from '@app/spurado-extended';
import {ApplicationDetailsActions, ThemeActions, UserActions} from '@app/state/spurado.actions';
import {SpuradoState} from '@app/state/spurado.state';
import {HeaderComponent} from "@app/layout/header/header.component";
import {FooterComponent} from "@app/layout/footer/footer.component";
import {MessageInfoComponent} from "@app/shared/message-info/message-info.component";
import {LoaderDirective} from "@app/shared/loader/loader.directive";

@Component({
    selector: 'spurado-root',
    standalone: true,
    imports: [
        AsyncPipe,
        NgIf,
        MatButton,
        MatIcon,
        RouterOutlet,
        TranslateModule,
        HeaderComponent,
        FooterComponent,
        MessageInfoComponent,
        LoaderDirective,
    ],
    templateUrl: './app.component.html',
    styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit, OnDestroy {

    private unsubscribeSubject: Subject<void>;

    conditionsAndCookiesAccepted = false;
    @Select(SpuradoState.applicationDetails) applicationDetails$: Observable<ApplicationDetails>;
    @Select(SpuradoState.user) loggedUser$: Observable<User>;
    @Select(SpuradoState.account) loggedAccount$: Observable<Account>;
    currentTheme: string;

    @HostBinding('class') componentCssClass;

    constructor(public oauthService: OAuthService,
                public commonService: CommonService,
                public breakpointService: BreakpointService,
                public translate: TranslateService,
                private snackbarService: SnackbarService,
                private overlayContainer: OverlayContainer,
                private router: Router,
                private store: Store,
    ) {
        this.unsubscribeSubject = new Subject<void>();
        translate.addLangs(['fr', 'nl']);
        translate.setDefaultLang('fr');
    }

    ngOnInit() {
        this.store.select(SpuradoState.theme)
            .pipe(takeUntil(this.unsubscribeSubject))
            .subscribe((theme: Theme) => {
                this.setTheme(theme.toLowerCase() + '-theme');
            });

        this.store.select(SpuradoState.account)
            .pipe(takeUntil(this.unsubscribeSubject))
            .subscribe((account: Account) => {
                if (account && account.organisation) {
                    this.store.dispatch(new ThemeActions.Load(account.organisation.configuration.selectedTheme.value));
                }
            });

        const lastAccepted = window.localStorage.getItem('conditionsCookiesConsentDate');
        if (lastAccepted) {
            this.conditionsAndCookiesAccepted = true;
        }
        if (this.conditionsAndCookiesAccepted) {
            this.configure();
        }


        this.oauthService.events
            .pipe(takeUntil(this.unsubscribeSubject))
            .subscribe(event => {
                if (event instanceof OAuthErrorEvent) {
                    console.error(event);
                    this.snackbarService.openSnackBarWithAction('common.error.session', Criticity.ERROR, 'common.actions.reconnect', () => this.oauthService.logOut());
                }
            });
    }

    closeCookieConsentPanel() {
        this.conditionsAndCookiesAccepted = true;
        window.localStorage.setItem('conditionsCookiesConsentDate', Date.now().toString());
        this.configure();
    }

    private configure() {
        this.oauthService.setStorage(sessionStorage);
        this.oauthService.configure(authConfig);
        this.oauthService.setupAutomaticSilentRefresh();
        this.oauthService
            .loadDiscoveryDocumentAndLogin({
                state: location.pathname
            })
            .then(() => {
                this.store.dispatch(new UserActions.Load());

                const redirectUrl = decodeURIComponent(this.oauthService.state);
                if (redirectUrl?.replace('/', '').trim()) {
                    void this.router.navigateByUrl(redirectUrl);
                } else {
                    this.store.dispatch(new ApplicationDetailsActions.Load());
                }
            });
    }

    private setTheme(theme) {
        const container = this.overlayContainer.getContainerElement();
        const classList = container.classList;
        if (classList && classList.replace) {
            if (classList.contains(this.currentTheme)) {
                classList.replace(this.currentTheme, theme);
            } else {
                classList.add(theme);
            }
        } else {
            //IE9 and IE9+ with no support of classList.replace
            const classes = container.className.split(' ');
            const i = classes.indexOf(this.currentTheme);
            if (i >= 0) {
                classes.splice(i, 1);
            }
            classes.push(theme);
            container.className = classes.join(' ');
        }

        this.componentCssClass = theme;
        this.currentTheme = theme;
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.unsubscribe();
    }
}
