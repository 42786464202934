// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#cookie {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 500 !important;
  background-color: rgba(0, 0, 0, 0.45) !important;
  visibility: visible;
  display: block;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: not-allowed;
}

.cookie {
  height: 40px;
}

.cookie-consent {
  text-align: center;
  margin-top: 15%;
  margin-left: 15%;
  margin-right: 15%;
  padding: 15px;
  background-color: white;
  cursor: auto;
  border: 5px solid #363636;
}

.neutral-spinner ::ng-deep mat-spinner.icity-theme .mat-progress-spinner circle, .neutral-spinner .icity-theme .mat-spinner circle {
  stroke: gray;
}

@media (max-width: 600px) {
  .cookie-consent {
    margin-left: 2%;
    margin-right: 2%;
  }
}
#page-container {
  min-height: 100%;
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr 48px;
  grid-template-columns: 1fr;
}

#header {
  position: relative;
  z-index: 10;
}

#footer {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,gDAAA;EACA,mBAAA;EACA,cAAA;EACA,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;EACA,mBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,YAAA;EACA,yBAAA;AACJ;;AAGI;EACI,YAAA;AAAR;;AAIA;EACI;IACI,eAAA;IACA,gBAAA;EADN;AACF;AAIA;EACI,gBAAA;EACA,kBAAA;EACA,aAAA;EACA,iCAAA;EACA,0BAAA;AAFJ;;AAKA;EACI,kBAAA;EACA,WAAA;AAFJ;;AAKA;EACI,WAAA;AAFJ","sourcesContent":["#cookie {\n    width: 100%;\n    height: 100%;\n    overflow: hidden;\n    z-index: 500 !important;\n    background-color: rgba(0, 0, 0, 0.45) !important;\n    visibility: visible;\n    display: block;\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    cursor: not-allowed;\n}\n\n.cookie {\n    height: 40px;\n}\n\n.cookie-consent {\n    text-align: center;\n    margin-top: 15%;\n    margin-left: 15%;\n    margin-right: 15%;\n    padding: 15px;\n    background-color: white;\n    cursor: auto;\n    border: 5px solid #363636;\n}\n\n.neutral-spinner {\n    ::ng-deep mat-spinner.icity-theme .mat-progress-spinner circle, .icity-theme .mat-spinner circle {\n        stroke: gray;\n    }\n}\n\n@media(max-width: 600px) {\n    .cookie-consent {\n        margin-left: 2%;\n        margin-right: 2%;\n    }\n}\n\n#page-container {\n    min-height: 100%;\n    position: relative;\n    display: grid;\n    grid-template-rows: auto 1fr 48px;\n    grid-template-columns: 1fr;\n}\n\n#header {\n    position: relative;\n    z-index: 10;\n}\n\n#footer {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
