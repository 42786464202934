import {Component, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {Store} from '@ngxs/store';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {BreakpointService} from '@app/service/breakpoint.service';
import {ClientService} from '@api/client.service';
import {CommonService} from '@api/common.service';
import {HolidayService} from '@api/holiday.service';
import {SnackbarService} from '@app/service/snackbar.service';
import {TimesheetCommonService} from '@app/service/timesheet-common.service';
import {TimesheetService} from '@app/service/timesheet.service';
import {
    Account,
    Action,
    AddLine,
    Client,
    HolidayDuration,
    LineStatus,
    PersonalHoliday,
    Task,
    Timesheet,
    TimesheetDay,
    TimesheetLine,
    TimesheetLineAuthorization,
    TimesheetMode,
    TimesheetStatus,
    WeekDay
} from '@app/spurado';
import {Criticity} from '@app/spurado-extended';
import {PendingApprovalsActions} from '@app/state/spurado.actions';
import {TimesheetClientComponent} from '@app/shared/timesheet/client/timesheet-client.component';
import {TimesheetCommentComponent} from '@app/shared/timesheet/comment/timesheet-comment.component';
import {TimesheetHolidayComponent} from '@app/shared/timesheet/holiday/timesheet-holiday.component';
import {TimesheetProjectListComponent} from '@app/shared/timesheet/project-list/timesheet-project-list.component';
import {TimesheetSubmitConfirmComponent} from '@app/shared/timesheet/submit-confirm/timesheet-submit-confirm.component';

@Component({
    selector: 'spurado-base-timesheet-table',
    templateUrl: './base-timesheet-table.component.html',
    styleUrls: ['./base-timesheet-table.component.scss'],
    standalone: true
})
export class BaseTimesheetTableComponent implements OnInit, OnDestroy {

    private unsubscribeSubject: Subject<void>;

    // Enumerations
    timesheetStatus = TimesheetStatus;
    timesheetMode = TimesheetMode;
    lineAction = TimesheetLineAuthorization;
    action = Action;

    // Instance variables
    totalDurationPerDay: Map<number, number>;
    days: WeekDay[];
    weekEndVisible: boolean;
    holidayEncodingAllowed: boolean;
    disableSubmitButton = false;
    showWarnings: boolean;
    forceSubmissionWithWarnings: boolean;
    locale: string;

    clientsForTasks: Client[];

    @HostListener('keydown', ['$event'])
    onComma(event) {
        const e = event as KeyboardEvent;
        const t = event.target as HTMLTextAreaElement;
        if (e.key === ',') {
            e.preventDefault();
            const currentValue = t.value;
            t.value = currentValue + '.';
        }
    }

    // Inputs
    _loggedAccount: Account;
    @Input()
    set loggedAccount(loggedAccount: Account) {
        this._loggedAccount = loggedAccount;
        if (loggedAccount) {
            this.buildDaysList(this._timesheet);
        }
    }

    get loggedAccount() {
        return this._loggedAccount;
    }

    private _timesheet: Timesheet;
    @Input()
    set timesheet(timesheet: Timesheet) {
        this._timesheet = timesheet;
        this.buildDaysList(this._timesheet);
    }

    get timesheet() {
        return this._timesheet;
    }

    @Input()
    mode: TimesheetMode;
    @Input()
    highlightedLineSequence: string;
    @Input()
    timesheetUnderRefresh: boolean;

    @Input()
    set displayWeekend(val: boolean) {
        this.weekEndVisible = val;
        this.buildDaysList(this.timesheet);
    }

    // Outputs
    @Output()
    refresh: EventEmitter<any>;
    @Output()
    displayWeekendChange: EventEmitter<boolean>;
    @Output()
    lineActionChange: EventEmitter<{ timesheetUuid: string, line: TimesheetLine, action: Action }> = new EventEmitter<{
        timesheetUuid: string,
        line: TimesheetLine,
        action: Action
    }>();

    constructor(
        public timesheetService: TimesheetService,
        public timesheetCommonService: TimesheetCommonService,
        public holidayService: HolidayService,
        public commonService: CommonService,
        public breakpointService: BreakpointService,
        public snackbarService: SnackbarService,
        public clientService: ClientService,
        public translateService: TranslateService,
        public clientDialog: MatDialog,
        public submissionConfirmationDialog: MatDialog,
        public commentDialog: MatDialog,
        public holidayDialog: MatDialog,
        public projectDialog: MatDialog,
        protected store: Store,
    ) {
        this.unsubscribeSubject = new Subject<void>();
        this.displayWeekendChange = new EventEmitter<boolean>();
        this.showWarnings = false;
        this.forceSubmissionWithWarnings = false;
    }

    ngOnInit(): void {
        this.holidayEncodingAllowed = this.isHolidayEncodingAllowed();
        this.timesheetService.timesheetUnderRefresh
            .pipe(takeUntil(this.unsubscribeSubject))
            .subscribe(timesheetUnderRefresh => {
                this.timesheetUnderRefresh = timesheetUnderRefresh;
            });
        this.clientService.getAllClients(this.loggedAccount.organisation.uuid).subscribe(
            clients => {
                this.clientsForTasks = clients;
            },
            error => {
                this.snackbarService.openGenericSnackBar('errors.common.missing_data', Criticity.ERROR);
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.unsubscribe();
    }

    openSubmissionConfirmationModal(enableSubmission: boolean): void {
        const submissionConfirmationRef = this.submissionConfirmationDialog.open(TimesheetSubmitConfirmComponent, {
            disableClose: true,
            autoFocus: true,
            data: {timesheet: this.timesheet, loggedAccount: this.loggedAccount, enableSubmission: enableSubmission}
        });

        const subscription = submissionConfirmationRef.afterClosed().subscribe(result => {
            if (result) {
                this.submitTimesheetForApproval();
            }
            subscription.unsubscribe();
        });
    }

    openProjectsModal(): void {
        const projectDialogRef = this.projectDialog.open(TimesheetProjectListComponent, {
            width: '80%',
            disableClose: true,
            autoFocus: true,
            data: {timesheet: this.timesheet, loggedAccount: this.loggedAccount, mode: this.mode}
        });

        projectDialogRef.afterClosed().subscribe((selectedTasks: Set<Task>) => {
            if (selectedTasks && selectedTasks.size > 0) {

                let linesToAdd: AddLine[] = [];
                selectedTasks.forEach(task => {
                    let lineToAdd = new AddLine();
                    lineToAdd.taskUuid = task.uuid;
                    if (task.client) {
                        lineToAdd.clientUuid = task.client.uuid;
                    }
                    linesToAdd.push(lineToAdd);
                });

                this.timesheetService.createNewTimesheetLine(this.loggedAccount.organisation.uuid, this.timesheet.uuid, linesToAdd, this.mode)
                    .catch(() => {
                        this.snackbarService.openErrorSnackBar();
                    });

            }
        });
    }

    openHolidayModal(day: WeekDay, mode: Action.ADD | Action.EDIT): void {
        if (this._timesheet.status === TimesheetStatus.EDITABLE && this.isWorkableDay(day) && this.isHolidayEncodingAllowed()) {
            const encodedHours: number = (this.totalDurationPerDay.get(day.number) !== undefined) ? this.totalDurationPerDay.get(day.number) : 0;

            const holidayDialogRef = this.holidayDialog.open(TimesheetHolidayComponent, {
                data: {day, mode, encodedHours}
            });

            holidayDialogRef.afterClosed().subscribe(holiday => {
                if (holiday) {
                    if (mode === Action.ADD) {
                        this.holidayService.createHoliday(this.loggedAccount.organisation.uuid, holiday).subscribe(
                            personalHoliday => {
                                this.timesheetService.askForReload();
                                this.snackbarService.openGenericSnackBar('timesheet.holiday.creation.success', Criticity.INFO);
                            },
                            error => {
                                this.snackbarService.openGenericSnackBar('timesheet.holiday.creation.failure', Criticity.ERROR);
                            }
                        );
                    } else {
                        if (holiday.duration) {
                            this.holidayService.updateHoliday(this.loggedAccount.organisation.uuid, holiday).subscribe(
                                value => {
                                    this.timesheetService.askForReload();
                                    this.snackbarService.openGenericSnackBar('timesheet.holiday.edition.success', Criticity.INFO);
                                },
                                error => {
                                    this.snackbarService.openGenericSnackBar('timesheet.holiday.edition.failure', Criticity.ERROR);
                                });
                        } else {
                            this.holidayService.deleteHoliday(this.loggedAccount.organisation.uuid, holiday).subscribe(
                                value => {
                                    this.timesheetService.askForReload();
                                    this.snackbarService.openGenericSnackBar('timesheet.holiday.edition.success', Criticity.INFO);
                                },
                                error => {
                                    this.snackbarService.openGenericSnackBar('timesheet.holiday.edition.failure', Criticity.ERROR);
                                });
                        }
                    }
                }
            });
        }
    }

    private isHolidayEncodingAllowed(): boolean {
        if (this.mode === TimesheetMode.USER) {
            return this._loggedAccount.organisation.configuration.enableEditPersonalHoliday.value;
        }
        return false;
    }

    openCommentModal(timesheetLine: TimesheetLine): void {
        const dialogRef = this.commentDialog.open(TimesheetCommentComponent, {
            data: timesheetLine,
            panelClass: 'comment-dialog'
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result !== undefined && timesheetLine.allowedActions.indexOf(TimesheetLineAuthorization.EDIT_COMMENT) !== -1) {
                this.timesheetService.updateComment(this.loggedAccount.organisation.uuid, this.timesheet.uuid, timesheetLine, this.mode, result)
                    .then(response => {
                        timesheetLine.comment = result;
                        this.snackbarService.openGenericSnackBar('timesheet.comment.edition.success', Criticity.INFO);
                    }, failure => {
                        this.snackbarService.openErrorSnackBar(failure);
                    });
            }
        });
    }

    openClientModal(timesheetLine: TimesheetLine): void {
        const dialogRef = this.clientDialog.open<TimesheetClientComponent>(TimesheetClientComponent, {
            data: {
                line: timesheetLine,
                clients: this.clientsForTasks
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.client) {
                this.timesheetService.updateClient(this.loggedAccount.organisation.uuid, this.timesheet.uuid, timesheetLine, this.mode, result.client.uuid)
                    .then(response => {
                        timesheetLine.client = result.client;
                        this.snackbarService.openGenericSnackBar('common.actions.success', Criticity.INFO);
                    }, failure => {
                        this.snackbarService.openErrorSnackBar(failure);
                    });
            }
        });
    }

    remove(holiday: PersonalHoliday): void {
        if (this.timesheet.status === TimesheetStatus.EDITABLE || this.mode === TimesheetMode.ADMIN) {
            this.holidayService.deleteHoliday(this.loggedAccount.organisation.uuid, holiday).subscribe(
                value => {
                    this.timesheetService.askForReload();
                    this.snackbarService.openGenericSnackBar('timesheet.holiday.deletion.success', Criticity.INFO);
                },
                error => {
                    this.snackbarService.openGenericSnackBar('timesheet.holiday.deletion.failure', Criticity.WARNING);
                });
        }
    }

    sanitizeInput(event): number {
        let duration: number;
        if (!event.target.value || event.target.value <= 0) {
            duration = 0;
        } else {
            event.target.value = event.target.value.replace(/,/g, '.');
            if (isFinite(event.target.value)) {
                duration = Number(event.target.value);
            } else {
                duration = 0;
            }
        }
        event.target.value = duration;
        return duration;
    }

    saveActual(line: TimesheetLine, day: TimesheetDay, event: any) {
        day.actualDuration = this.sanitizeInput(event);

        const isReopening = line.status !== LineStatus.OPEN;
        // Already reopen now so that screens are updated.  It will happen soon on the server anyway, and will refresh if it fails
        line.status = LineStatus.OPEN;
        // Recalculate immediately, so that we block submit in case things are invalid
        this.computeTotalDurationPerDay();
        this.timesheetService.updateActual(this.loggedAccount.organisation.uuid, this.timesheet.uuid, line, day, this.mode).then(
            success => {
                this.snackbarService.openGenericSnackBar('timesheet.actual.creation.success', Criticity.INFO);
                if (isReopening) {
                    // Editing a line can bring it to the open status.  In this case, we should refresh a few things
                    // Pour faire un reload des allowed actions etc.
                    this.timesheetService.askForReload();
                    this.store.dispatch(new PendingApprovalsActions.Load(this.loggedAccount.organisation.uuid));
                }
            },
            failure => {
                event.target.value = day.actualDuration;
                this.snackbarService.openErrorSnackBar(failure);
                this.timesheetService.askForReload();
                // ca revert le changement fait par l'utilisteur,
                // mais cela le débloque, et recharge les changement fait par un autre user
            }
        );
    }

    saveActualFromDailyPointing(line: TimesheetLine, day: TimesheetDay, event: number) {
        this.saveActual(line, day, {target: {value: '' + event}});
    }

    buildDaysList(timesheet: Timesheet): void {
        if (timesheet && this._loggedAccount) {
            const daysList = [];
            for (let i = 1; i <= (this.weekEndVisible ? 7 : 5); i++) {
                daysList.push(timesheet.week[i]);
            }
            this.days = this.sortByDaysInTimesheet(daysList);
            this.computeTotalDurationPerDay();
        }
    }

    sortByDaysInTimesheet(weekDays: WeekDay[]): WeekDay[] {
        return weekDays.sort((a: TimesheetDay, b: TimesheetDay): number => {
            if (a.number < b.number) {
                return -1;
            } else if (a.number > b.number) {
                return 1;
            } else {
                return 0;
            }
        });
    }

    computeTotalDurationPerDay(): void {
        this.totalDurationPerDay = new Map<number, number>();

        if (this.timesheet.timesheetLines && this.timesheet.timesheetLines.length > 0) {
            for (const timesheetLine of this.timesheet.timesheetLines) {
                for (const day of timesheetLine.timesheetDays) {
                    if (this.timesheet.week[day.number].editable) {
                        const weekDay: WeekDay = this.days.find(d => d.number === day.number);
                        let previousDuration = this.totalDurationPerDay.get(day.number);
                        let currentTotalDuration: number;
                        if (weekDay) {
                            if (!previousDuration) {
                                previousDuration = 0;
                            }
                            currentTotalDuration = previousDuration + day.actualDuration;
                        }
                        this.totalDurationPerDay.set(day.number, currentTotalDuration);
                    } else {
                        this.totalDurationPerDay.set(day.number, -1);
                    }
                }
            }
        }
    }

    isSubmitButtonDisabled(): boolean {
        return this.timesheetUnderRefresh;
    }

    isActualCellDisabled(timesheet: Timesheet, dayNumber: number): boolean {
        // TODO-COULD : On pourrait optimiser un peu ce code, qui est certainement souvent appelé
        // TODO-SHOULD : On devrait sans doute recevoir cette valeur du backend -> weekDay.editable (editable serait
        //  calculé par le backend et le UI se contenterait d'interpréter le résultat)
        const weekDay: WeekDay = this.timesheet.week[dayNumber];
        const dayDisabled =
            (!weekDay.weekDay && !this.loggedAccount.organisation.configuration.enableWorkDuringWeekend.value)
            || !weekDay.editable
            || (weekDay.legalHoliday && !this.loggedAccount.organisation.configuration.enableWorkDuringLegalHoliday.value)
            || weekDay.personalHoliday && weekDay.personalHoliday.duration === HolidayDuration.FD;

        return (this.mode === TimesheetMode.APPROVAL || timesheet.status !== TimesheetStatus.EDITABLE || dayDisabled || this.timesheetUnderRefresh)
            && !(this.mode === TimesheetMode.ADMIN && timesheet.status === TimesheetStatus.FINAL);
    }

    isWorkableDay(day: WeekDay): boolean {
        const legalCompliant = !day.legalHoliday || this.loggedAccount.organisation.configuration.enableWorkDuringLegalHoliday.value;
        const dayNumberCompliant = day.weekDay || this.loggedAccount.organisation.configuration.enableWorkDuringWeekend.value;
        return legalCompliant && dayNumberCompliant;
    }

    private submitTimesheetForApproval() {
        this.disableSubmitButton = true;
        this.timesheetService.submitTimesheetForApproval(this.loggedAccount.organisation.uuid, this.timesheet).then(
            success => {
                this.snackbarService.openGenericSnackBar('timesheet.submit.success', Criticity.INFO);
                this.store.dispatch(new PendingApprovalsActions.Load(this.loggedAccount.organisation.uuid));
            },
            error => {
                this.snackbarService.openErrorSnackBar();
                this.disableSubmitButton = false;
            }
        );
    }

    toggleWeekend() {
        this.displayWeekendChange.emit(!this.weekEndVisible);
    }

    duplicateLine(timesheetLine: TimesheetLine): void {
        let newLineToAdd = new AddLine();
        newLineToAdd.taskUuid = timesheetLine.task.uuid;
        this.timesheetService.createNewTimesheetLine(this.loggedAccount.organisation.uuid, this.timesheet.uuid, [newLineToAdd], this.mode)
            .then(timesheetLines => {
                this.lineActionChange.emit({timesheetUuid: this.timesheet.uuid, line: timesheetLines[0], action: Action.COPY});
            })
            .catch(() => {
                this.snackbarService.openErrorSnackBar();
            });
    }

    addAllFavoriteTasksToTimesheet(): void {
        this.timesheetService.createTimesheetLinesFromFavorites(this.loggedAccount.organisation.uuid, this.timesheet.uuid, this.mode)
            .then(timesheetLines => {
                // if(timesheetLines && timesheetLines.length > 0){
                this.lineActionChange.emit({timesheetUuid: this.timesheet.uuid, line: timesheetLines[0], action: Action.COPY});
                // } else {
                //     this.snackbarService.openGenericSnackBar('tasks.favorites.no_favorite_yet', Criticity.WARNING);
                // }
            })
            .catch(() => {
                this.snackbarService.openErrorSnackBar();
            });
    }

}
