import {Component} from '@angular/core';
import {TimesheetMode} from '@app/spurado';
import {TimesheetComponent} from '@app/shared/timesheet/timesheet.component';

@Component({
    selector: 'spurado-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    standalone: true,
    imports: [
        TimesheetComponent,
    ]
})
export class HomeComponent {

    timesheetMode = TimesheetMode;
}
