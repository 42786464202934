import {Provider} from "@angular/core";
import {MatDateFormats} from "@angular/material/core";
import {DATE_DAY_FORMAT} from '@app/spurado-extended';
import {MatPaginatorIntl} from "@angular/material/paginator";
import {SpuradoPaginatorIntlService} from '@app/service/spurado-paginator-intl.service';

export const matDateFormats: MatDateFormats = DATE_DAY_FORMAT;

export const matPaginatorIntlProvider: Provider = {
    provide: MatPaginatorIntl,
    useClass: SpuradoPaginatorIntlService
};
