import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import {Account, Task, Timesheet, TimesheetMode} from '@app/spurado';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {TaskService} from '@api/task.service';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import { TranslateModule } from '@ngx-translate/core';
import { ChipComponent } from '@app/shared/chip/chip.component';
import { ChipListComponent } from '@app/shared/chip-list/chip-list.component';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatGridList, MatGridTile, MatGridTileText, MatGridTileHeaderCssMatStyler, MatGridTileFooterCssMatStyler } from '@angular/material/grid-list';
import { LoaderDirective } from '@app/shared/loader/loader.directive';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'spurado-timesheet-project-list',
    templateUrl: './timesheet-project-list.component.html',
    styleUrls: ['./timesheet-project-list.component.scss'],
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        NgIf,
        MatFormField,
        MatLabel,
        MatInput,
        ReactiveFormsModule,
        MatIconButton,
        MatSuffix,
        MatIcon,
        MatTooltip,
        LoaderDirective,
        MatGridList,
        NgFor,
        MatGridTile,
        MatGridTileText,
        MatGridTileHeaderCssMatStyler,
        MatGridTileFooterCssMatStyler,
        MatCheckbox,
        ChipListComponent,
        ChipComponent,
        MatDialogActions,
        MatButton,
        MatDialogClose,
        TranslateModule,
    ],
})
export class TimesheetProjectListComponent implements OnInit {
    loading: boolean;
    showAllProjects = true;
    timesheetMode = TimesheetMode;
    gridCols = 1;

    originalTaskList: { task: Task; selected: boolean }[];
    filteredTaskList: { task: Task; selected: boolean }[];
    selectedTasks: Set<Task>;
    favoritesSelected: boolean;
    searchFieldFormControl: FormControl;
    numberOfFavoriteTasks: number;

    constructor(
        public projectDialogRef: MatDialogRef<TimesheetProjectListComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            timesheet: Timesheet;
            loggedAccount: Account;
            mode: TimesheetMode;
        },
        private taskService: TaskService,
        private breakpointObserver: BreakpointObserver
    ) {
        this.originalTaskList = [];
        this.selectedTasks = new Set<Task>();
        this.filteredTaskList = [];
        this.loading = true;
        this.favoritesSelected = false;
        this.numberOfFavoriteTasks = 0;
        this.searchFieldFormControl = new FormControl('');
    }

    ngOnInit() {
        this.getTasks();
        this.setupGridCols();
    }

    private setupGridCols() {
        this.breakpointObserver
        .observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge,
        ])
        .subscribe((result) => {
            if (result.breakpoints[Breakpoints.XSmall]) {
                this.gridCols = 1;
            }
            if (result.breakpoints[Breakpoints.Small]) {
                this.gridCols = 2;
            }
            if (result.breakpoints[Breakpoints.Medium]) {
                this.gridCols = 3;
            }
            if (
                result.breakpoints[Breakpoints.Large] ||
                result.breakpoints[Breakpoints.XLarge]
            ) {
                this.gridCols = 5;
            }
        });
    }

    onNoClick(): void {
        this.projectDialogRef.close();
    }

    createEntriesFromTemplate(): void {
        this.favoritesSelected = !this.favoritesSelected;
        this.originalTaskList.forEach((item) => {
            if (item.task.favorite) {
                if (!this.favoritesSelected) {
                    item.selected = false;
                    this.selectedTasks.delete(item.task);
                } else {
                    item.selected = true;
                    this.selectedTasks.add(item.task);
                }
            }
        });
        this.syncFilteredList(this.searchFieldFormControl.value);
    }

    updateSelectedItems(element: { task: Task; selected: boolean }) {
        let selectedFavorites = 0;
        if (!element.selected) {
            this.selectedTasks.add(element.task);
            element.selected = true;
        } else {
            this.selectedTasks.delete(element.task);
            element.selected = false;
        }
        this.selectedTasks.forEach((value) => {
            if (value.favorite) {
                selectedFavorites++;
            }
        });
        if (selectedFavorites === 0) {
            this.favoritesSelected = false;
        } else if (selectedFavorites === this.numberOfFavoriteTasks) {
            this.favoritesSelected = true;
        }
    }

    private getTasks(): void {
        if (this.data.mode === TimesheetMode.ADMIN) {
            this.taskService
            .getAllTasks(this.data.loggedAccount.organisation.uuid)
            .subscribe((tasks) => {
                this.getTaskHelper(tasks);
            });
        } else {
            this.taskService
            .getTasksForTimesheet(
                this.data.loggedAccount.organisation.uuid,
                this.data.timesheet.uuid,
                this.showAllProjects
            )
            .subscribe((tasks) => {
                this.getTaskHelper(tasks);
            });
        }
    }

    private getTaskHelper(tasks: Task[]): void {
        this.originalTaskList = tasks.map((t) => ({
            task: t,
            selected: false,
        }));
        this.numberOfFavoriteTasks = tasks.filter((t) => t.favorite).length;
        this.searchFieldFormControl.valueChanges
        .pipe(
            startWith(''),
            map((input: string | null) => {
                this.syncFilteredList(input);
            })
        )
        .subscribe();
        this.loading = false;
    }

    deselectAll() {
        this.selectedTasks.clear();
        this.originalTaskList.forEach((item) => {
            item.selected = false;
        });
        this.favoritesSelected = false;
        this.syncFilteredList(this.searchFieldFormControl.value);
    }

    private syncFilteredList(filterText: string | null) {
        if (!filterText || filterText.length === 0) {
            this.filteredTaskList = this.originalTaskList;
        } else {
            const searchString = filterText.toLowerCase();
            this.filteredTaskList = this.originalTaskList.filter(
                (el) =>
                    el.task.name.toLowerCase().includes(searchString) ||
                    (el.task.code &&
                        el.task.code.toLowerCase().includes(searchString))
            );
        }
        // TODO-SHOULD : on devrait limiter la taille de la filteredTaskList
    }

    updateShowAllCheckbox(event) {
        this.showAllProjects = event.checked;
        this.selectedTasks = new Set();
        this.searchFieldFormControl.reset();
        this.getTasks();
    }
}
