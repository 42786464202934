// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timesheet-status {
  margin: 5px 0;
  text-align: center;
}

.timesheet-status span {
  padding: 3px 6px;
  border-radius: 10px;
}

.editable {
  background-color: #f3feed;
  border: solid 1px #87cd38;
  color: #87cd38;
}

.pre-final {
  background-color: #fefaed;
  border: solid 1px #fabf0d;
  color: #fabf0d;
}

.final {
  background-color: #FEEEED;
  border: solid 1px #CD3838;
  color: #CD3838;
}

.status-clickable {
  cursor: pointer;
}

.status-no-action {
  cursor: not-allowed;
}

@media (max-width: 980px) {
  .week-sum-container {
    margin-right: 20px;
  }
  .week-sum-container-inversed {
    margin-left: 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/timesheet/status/timesheet-status.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,yBAAA;EACA,yBAAA;EACA,cAAA;AACJ;;AAEA;EACI,yBAAA;EACA,yBAAA;EACA,cAAA;AACJ;;AAEA;EACI,yBAAA;EACA,yBAAA;EACA,cAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI;IACI,kBAAA;EACN;EAEE;IACI,iBAAA;EAAN;AACF","sourcesContent":[".timesheet-status {\n    margin: 5px 0;\n    text-align: center;\n}\n\n.timesheet-status span {\n    padding: 3px 6px;\n    border-radius: 10px;\n}\n\n.editable {\n    background-color: #f3feed;\n    border: solid 1px #87cd38;\n    color: #87cd38;\n}\n\n.pre-final {\n    background-color: #fefaed;\n    border: solid 1px #fabf0d;\n    color: #fabf0d;\n}\n\n.final {\n    background-color: #FEEEED;\n    border: solid 1px #CD3838;\n    color: #CD3838;\n}\n\n.status-clickable {\n    cursor: pointer;\n}\n\n.status-no-action {\n    cursor: not-allowed;\n}\n\n@media (max-width: 980px) {\n    .week-sum-container {\n        margin-right: 20px;\n    }\n\n    .week-sum-container-inversed {\n        margin-left: 20px;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
