import {Component, Inject, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import {Account, Timesheet, TimesheetValidation} from '@app/spurado';
import {AccountService} from '@api/account.service';
import {TimesheetService} from '@app/service/timesheet.service';
import {SnackbarService} from '@app/service/snackbar.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { MatCheckbox } from '@angular/material/checkbox';
import { TimesheetValidationComponent } from '@app/shared/timesheet/validation/timesheet-validation.component';
import { NgIf } from '@angular/common';

@Component({
    selector: 'spurado-timesheet-submit-confirm',
    templateUrl: './timesheet-submit-confirm.component.html',
    styleUrls: ['./timesheet-submit-confirm.component.scss'],
    standalone: true,
    imports: [NgIf, MatDialogTitle, MatDialogContent, TimesheetValidationComponent, MatCheckbox, MatDialogActions, MatButton, MatDialogClose, TranslateModule]
})
export class TimesheetSubmitConfirmComponent implements OnInit {

    loading = true;
    continue = false;

    loggedAccount: Account;
    timesheet: Timesheet;
    enableSubmission: boolean;
    timesheetValidation: TimesheetValidation;
    submitChecksEnabled: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: { timesheet: Timesheet, loggedAccount: Account, enableSubmission: boolean },
                public accountService: AccountService,
                public dialogRef: MatDialogRef<TimesheetSubmitConfirmComponent>,
                private snackbarService: SnackbarService,
                public timesheetService: TimesheetService) {
        this.timesheet = data.timesheet;
        this.loggedAccount = data.loggedAccount;
        this.enableSubmission = data.enableSubmission;
        this.submitChecksEnabled = false;
    }

    ngOnInit() {
        this.submitChecksEnabled = this.loggedAccount.organisation.configuration.enableSubmitChecks.value;
        if (this.submitChecksEnabled) {
            this.timesheetService.validateTimesheetBeforeSubmission(this.loggedAccount.organisation.uuid, this.timesheet).then(
                result => {
                    this.timesheetValidation = result;
                    if (this.timesheetValidation.hoursInDayIssues) {
                        this.timesheetValidation.hoursInDayIssues.sort(function (a, b) {
                            return new Date(a.date).getTime() - new Date(b.date).getTime();
                        });
                    }
                    this.loading = false;
                }
            ).catch(
                error => {
                    this.snackbarService.openErrorSnackBar();
                }
            );
        }
    }

    get atLeastOneIssue(): boolean {
        if (this.timesheetValidation) {
            if (this.timesheetValidation.emptyCommentIssues && this.timesheetValidation.emptyCommentIssues.length > 0) {
                return true;
            } else if (this.timesheetValidation.hoursInDayIssues && this.timesheetValidation.hoursInDayIssues.length > 0) {
                return true;
            } else if (this.timesheetValidation.hoursInWeekIssues) {
                return true;
            }
        }
        return false;
    }

    updateContinueCheckbox(event) {
        this.continue = event.checked;
    }

    onNoClick() {
        this.dialogRef.close(false);
    }

}
