import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AccountDelegation} from '@app/spurado';

@Injectable({
    providedIn: 'root'
})
export class AccountDelegationService {
    private apiUrl: string = environment.apiUrl;

    constructor(private httpClient: HttpClient) {
    }

    getDelegatees(orgUuid: string): Observable<AccountDelegation[]> {
        return this.httpClient.get<AccountDelegation[]>(this.getUrl(orgUuid, 'delegatees'));
    }

    getDelegators(orgUuid: string): Observable<AccountDelegation[]> {
        return this.httpClient.get<AccountDelegation[]>(this.getUrl(orgUuid, 'delegators'));
    }

    getDelegatorsSummaries(orgUuid: string): Observable<AccountDelegation[]> {
        return this.httpClient.get<AccountDelegation[]>(this.getUrl(orgUuid, 'delegators/summary'));
    }

    createAccountDelegation(orgUuid: string, accountDelegation: AccountDelegation): Observable<AccountDelegation> {
        return this.httpClient.post<AccountDelegation>(this.getUrl(orgUuid), accountDelegation);
    }

    updateAccountDelegation(orgUuid: string, accountDelegation: AccountDelegation): Observable<AccountDelegation> {
        return this.httpClient.put<AccountDelegation>(this.getUrl(orgUuid, accountDelegation.uuid), accountDelegation);
    }

    deleteAccountDelegation(orgUuid: string, accountDelegation: AccountDelegation): Observable<void> {
        return this.httpClient.delete<void>(this.getUrl(orgUuid, accountDelegation.uuid));
    }

    private getUrl(orgUuid: string, endpoint?: number | string): string {
        return `${this.apiUrl}${orgUuid}/account-delegations${endpoint ? '/' + endpoint :  ''}`;
    }
}
