// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
  min-height: 40px;
  max-height: 120px;
  overflow: auto;
}

.chip-list {
  display: flex;
  flex-wrap: wrap;
}

.horizontal {
  flex-direction: row;
}

.vertical {
  flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/chip-list/chip-list.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,cAAA;AACJ;;AAEA;EACI,aAAA;EACA,eAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,sBAAA;AACJ","sourcesContent":[":host {\n    display: block;\n    min-height: 40px;\n    max-height: 120px;\n    overflow: auto;\n}\n\n.chip-list {\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.horizontal {\n    flex-direction: row;\n}\n\n.vertical {\n    flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
