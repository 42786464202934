import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Client} from '@app/spurado';

@Injectable({
    providedIn: 'root'
})
export class ClientService {

    private apiUrl: string = environment.apiUrl;
    private readonly clientsPath: string = '/clients';

    constructor(private httpClient: HttpClient) {
    }

    getAllClients(orgUuid: string, filter?: string): Observable<Array<Client>> {
        let requestParams: HttpParams = new HttpParams();
        if (filter && filter.length >= 3) {
            requestParams = requestParams.append('filter', filter);
            return this.httpClient.get<Array<Client>>(this.apiUrl + `${orgUuid}${this.clientsPath}`, {params: requestParams});
        }
        return this.httpClient.get<Array<Client>>(this.apiUrl + `${orgUuid}${this.clientsPath}`);
    }

    createClient(orgUuid: string, client: Client): Observable<any> {
        return this.httpClient.post<Client>(this.apiUrl + `${orgUuid}${this.clientsPath}`, client);
    }

    updateClientName(orgUuid: string, client: Client): Observable<any> {
        return this.httpClient.put<Client>(this.apiUrl + `${orgUuid}${this.clientsPath}/${client.uuid}/name`, client.name);
    }

}
