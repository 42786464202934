import {ChangeDetectionStrategy, Component, HostBinding, Input} from '@angular/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';

export type LoaderMode = 'fullScreen' | 'inline' | 'overlay';

@Component({
    selector: 'spurado-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatProgressSpinner],
})
export class LoaderComponent {
    @HostBinding('class')
    @Input()
    mode: LoaderMode;

    @Input()
    diameter: number;
}
