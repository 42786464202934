// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mat-list-base {
  padding-top: 0;
}

#main-message-alert {
  padding: 1rem;
}
#main-message-alert > div {
  text-align: center;
  opacity: 1;
  transition: opacity 1s ease;
}

#main-message-alert:hover {
  border-radius: 10px;
  cursor: pointer;
  background-color: #a6e5ff;
}

@media (max-width: 600px) {
  .mat-list-base {
    padding: 15px 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/shared/message-info/message-info.component.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,aAAA;AACJ;AACI;EACI,kBAAA;EACA,UAAA;EACA,2BAAA;AACR;;AAGA;EACI,mBAAA;EACA,eAAA;EACA,yBAAA;AAAJ;;AAGA;EACI;IACI,eAAA;EAAN;AACF","sourcesContent":[".mat-list-base {\n    padding-top: 0;\n}\n\n#main-message-alert {\n    padding: 1rem;\n\n    > div {\n        text-align: center;\n        opacity: 1;\n        transition: opacity 1s ease;\n    }\n}\n\n#main-message-alert:hover {\n    border-radius: 10px;\n    cursor: pointer;\n    background-color: #a6e5ff;\n}\n\n@media (max-width: 600px) {\n    .mat-list-base {\n        padding: 15px 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
