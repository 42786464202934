// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oops-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.oops-container img {
  height: 400px;
}

.oops-content h1 {
  font-family: "Frijole", cursive !important;
  font-size: 42px;
  text-transform: uppercase;
}

p:last-of-type {
  margin-bottom: 40px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/oops/oops.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,0CAAA;EACA,eAAA;EACA,yBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ","sourcesContent":[".oops-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.oops-container img {\n    height: 400px;\n}\n\n.oops-content h1 {\n    font-family: 'Frijole', cursive !important;\n    font-size: 42px;\n    text-transform: uppercase;\n}\n\np:last-of-type {\n    margin-bottom: 40px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
