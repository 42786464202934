import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationDetails, InfoMessage } from '@app/spurado';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    private apiUrl = environment.apiUrl;

    constructor(private httpClient: HttpClient) {
    }

    getApplicationDetails(): Observable<ApplicationDetails> {
        return this.httpClient.get<ApplicationDetails>(this.apiUrl + `commons/version`);
    }

    getMessageInfos(orgUuid: string): Observable<Array<InfoMessage>> {
        return this.httpClient.get<Array<InfoMessage>>(this.apiUrl + `${orgUuid}/commons/messages`);
    }
}
