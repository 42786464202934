import {Provider} from "@angular/core";
import {OAuthModuleConfig, OAuthService} from "angular-oauth2-oidc";
import {authConfig} from '@app/oidc/auth.config';
import {ImpersonalOAuthService} from '@app/service/impersonal-oauth.service';

const excludedPaths = ['commons/version', authConfig.issuer.match(/https?:\/\/[^\/]*\//g)[0]];

export function customUrlValidation(url: string): boolean {
    for (const path of excludedPaths) {
        if (url.indexOf(path) !== -1) {
            return false; // We don't need the token on this call
        }
    }
    return true; // We need the token on this call
}

export const oauthClientConfig: OAuthModuleConfig = {
    resourceServer: {
        allowedUrls: ['/api/', 'http://localhost:8080/api/'],
        sendAccessToken: true,
        customUrlValidation
    }
}

export const oauthServiceProvider: Provider = {
    provide: OAuthService,
    useClass: ImpersonalOAuthService
}
