import {APP_INITIALIZER, Provider} from "@angular/core";
import {AppInitService} from "./strategy/app-init-service.service";

export function initializeApp(appInitService: AppInitService) {
    return () => appInitService;
}

export const appInitializerProvider: Provider =         {
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    multi: true,
    deps: [AppInitService]
}
