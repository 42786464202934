// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.timesheet-validation-check ul {
  list-style: none;
}
.timesheet-validation-check li {
  margin-top: 8px;
}

.timesheet-validation-line {
  padding-left: 5px;
  vertical-align: 6px;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/timesheet/validation/timesheet-validation.component.scss"],"names":[],"mappings":"AACI;EACI,gBAAA;AAAR;AAGI;EACI,eAAA;AADR;;AAKA;EACI,iBAAA;EACA,mBAAA;AAFJ","sourcesContent":[".timesheet-validation-check {\n    ul {\n        list-style: none;\n    }\n\n    li {\n        margin-top: 8px;\n    }\n}\n\n.timesheet-validation-line {\n    padding-left: 5px;\n    vertical-align: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
